import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/vercel/path0/src/layout/Default.js";
export const _frontmatter = {};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const Accordion = makeShortcode("Accordion");
const AccordionItem = makeShortcode("AccordionItem");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h2>{`Latest Release`}</h2>
    <p>{`This is the changelog information of latest version and if you have any ideas or feedback for improvements in the next version please tell us from the feedback section`}</p>
    <div style={{
      "backgroundColor": "rgb(244, 242, 252)",
      "color": "rgb(135, 91, 247)",
      "padding": "1rem",
      "borderBottom": "1px solid #875bf7",
      "padding": "1rem"
    }}>
  <p style={{
        "fontWeight": "700"
      }}>27 March 2025</p>
    </div>
    <h3><strong parentName="h3">{`Website Updates`}</strong>{` `}<br /></h3>
    <p><strong parentName="p">{`Website Documentation - Release Version : 2.76.0`}</strong>{` `}<br /></p>
    <h3><strong parentName="h3">{`Content`}</strong></h3>
    <p><strong parentName="p">{`Feature`}</strong>{`: New `}<inlineCode parentName="p">{`Figma to Code`}</inlineCode>{` Banner in Homepage. Elevating ideas into development effortless.`}</p>
    <p><strong parentName="p">{`Feature`}</strong>{`: New Cookie Policy implementation alongside its article page.`}</p>
    <p><strong parentName="p">{`iOS (Legion-iOS-UIKit) - Release Version : 1.1.0`}</strong>{` `}<br /></p>
    <p><strong parentName="p">{`Update`}</strong>{`: Updated `}<inlineCode parentName="p">{`Color`}</inlineCode>{` for better accessibility.`}</p>
    <p><strong parentName="p">{`Update`}</strong>{`: Introduced new `}<inlineCode parentName="p">{`Font`}</inlineCode>{` to improve readability.`}</p>
    <p><strong parentName="p">{`Update`}</strong>{`: Standardized `}<inlineCode parentName="p">{`Radius`}</inlineCode>{` for a consistent design.`}</p>
    <p><strong parentName="p">{`New`}</strong>{`: Added new component for UIKit: `}<inlineCode parentName="p">{`TextField`}</inlineCode>{`, `}<inlineCode parentName="p">{`Button`}</inlineCode>{`, `}<inlineCode parentName="p">{`Snackbar`}</inlineCode>{`, `}<inlineCode parentName="p">{`CheckBox`}</inlineCode>{`, `}<inlineCode parentName="p">{`Avatar`}</inlineCode>{`, `}<inlineCode parentName="p">{`Badge`}</inlineCode>{`, `}<inlineCode parentName="p">{`Accordion`}</inlineCode>{`, `}<inlineCode parentName="p">{`Alert`}</inlineCode>{`, `}<inlineCode parentName="p">{`Anchor Text`}</inlineCode>{`, `}<inlineCode parentName="p">{`Bottom Sheet`}</inlineCode>{`, `}<inlineCode parentName="p">{`Divider`}</inlineCode>{`, `}<inlineCode parentName="p">{`Radio Button`}</inlineCode>{`, `}<inlineCode parentName="p">{`Snackbar`}</inlineCode>{`, `}<inlineCode parentName="p">{`Switch`}</inlineCode>{`, and `}<inlineCode parentName="p">{`Tooltip`}</inlineCode>{`. `}<br /></p>
    <h3><strong parentName="h3">{`Component Design`}</strong></h3>
    <ul>
      <li parentName="ul">
        <br />
      </li>
    </ul>
    <h3><strong parentName="h3">{`Pattern Updates`}</strong></h3>
    <ul>
      <li parentName="ul">
        <br />
      </li>
    </ul>
    <h2>{`Previous Release`}</h2>
    <p>{`Find the changelog information on the previous version by clicking the section below`}</p>
    <Accordion mdxType="Accordion">
      <AccordionItem title="10 January 2025" mdxType="AccordionItem">
        <h3><strong parentName="h3">{`Website Updates`}</strong>{` `}<br />{` `}<br /></h3>
        <p><strong parentName="p">{`Website Documentation - Release Version : 2.75.0`}</strong>{` `}<br /></p>
        <h3><strong parentName="h3">{`Content`}</strong>{` `}<br />{` `}<br /></h3>
        <p><strong parentName="p">{`Update`}</strong>{`: `}<inlineCode parentName="p">{`Color`}</inlineCode>{`, `}<inlineCode parentName="p">{`Radius`}</inlineCode>{`, and `}<inlineCode parentName="p">{`Typography`}</inlineCode>{` page to include iOS (Swift) platform.`}</p>
        <p><strong parentName="p">{`Update`}</strong>{`: Add `}<inlineCode parentName="p">{`MyTEnS`}</inlineCode>{` to list of available Illustration asset.`}</p>
        <h3><strong parentName="h3">{`Component Design`}</strong>{` `}<br />{` `}<br /></h3>
        <ul>
          <li parentName="ul">
            <br />
          </li>
        </ul>
        <h3><strong parentName="h3">{`Pattern Updates`}</strong>{` `}<br />{` `}<br /></h3>
        <ul>
          <li parentName="ul">
            <br />
          </li>
        </ul>
      </AccordionItem>
      <AccordionItem title="2 January 2025" mdxType="AccordionItem">
        <h3><strong parentName="h3">{`Website Updates`}</strong>{` `}<br />{` `}<br /></h3>
        <p><strong parentName="p">{`Website Documentation - Release Version : 2.75.0`}</strong>{` `}<br /></p>
        <h3><strong parentName="h3">{`Content`}</strong>{` `}<br />{` `}<br /></h3>
        <p><strong parentName="p">{`New`}</strong>{`: Illustration Page is up with `}<inlineCode parentName="p">{`Antares Eazy`}</inlineCode>{` and `}<inlineCode parentName="p">{`Pijar Belajar`}</inlineCode>{` products`}</p>
        <h3><strong parentName="h3">{`Component Design`}</strong>{` `}<br />{` `}<br /></h3>
        <ul>
          <li parentName="ul">
            <br />
          </li>
        </ul>
        <h3><strong parentName="h3">{`Pattern Updates`}</strong>{` `}<br />{` `}<br /></h3>
        <ul>
          <li parentName="ul">
            <br />
          </li>
        </ul>
      </AccordionItem>
      <AccordionItem title="22 November 2024" mdxType="AccordionItem">
        <h3><strong parentName="h3">{`Website Updates`}</strong>{` `}<br /></h3>
        <p><strong parentName="p">{`Website Documentation - Release Version : 2.74.0`}</strong>{` `}<br /></p>
        <h3><strong parentName="h3">{`Content`}</strong>{` `}<br />{` `}<br /></h3>
        <p><strong parentName="p">{`New`}</strong>{`: Legion Theme Generator is now up! `}<br /></p>
        <h3><strong parentName="h3">{`Component Design`}</strong>{` `}<br />{` `}<br /></h3>
        <ul>
          <li parentName="ul">
            <br />
          </li>
        </ul>
        <h3><strong parentName="h3">{`Pattern Updates`}</strong>{` `}<br />{` `}<br /></h3>
        <ul>
          <li parentName="ul">
            <br />
          </li>
        </ul>
      </AccordionItem>
      <AccordionItem title="16 October 2024" mdxType="AccordionItem">
        <h3><strong parentName="h3">{`Content`}</strong>{` `}<br />{` `}<br /></h3>
        <p><strong parentName="p">{`New`}</strong>{`: `}<inlineCode parentName="p">{`Installation`}</inlineCode>{` page for `}<inlineCode parentName="p">{`React Native`}</inlineCode>{` now available `}<br /></p>
        <h3><strong parentName="h3">{`Component Design`}</strong></h3>
        <ul>
          <li parentName="ul">
            <br />
          </li>
        </ul>
        <h3><strong parentName="h3">{`Pattern Updates`}</strong>{` `}<br />{` `}<br /></h3>
        <p><strong parentName="p">{`New`}</strong>{`: `}<inlineCode parentName="p">{`Landing Page`}</inlineCode>{` guideline page now available! `}<br /></p>
        <p><strong parentName="p">{`Update`}</strong>{`: `}<inlineCode parentName="p">{`Login and Register`}</inlineCode>{` website page now updated with latest UU PDP Act Compliance. `}<br /></p>
      </AccordionItem>
      <AccordionItem title="12 September 2024" mdxType="AccordionItem">
        <h3><strong parentName="h3">{`Content`}</strong>{` `}<br />{` `}<br /></h3>
        <p><strong parentName="p">{`New`}</strong>{`: `}<inlineCode parentName="p">{`Installation`}</inlineCode>{` page for `}<inlineCode parentName="p">{`React Native`}</inlineCode>{` now available `}<br /></p>
        <h3><strong parentName="h3">{`Component Design`}</strong>{` `}<br />{` `}<br /></h3>
        <ul>
          <li parentName="ul">
            <br />
          </li>
        </ul>
        <h3><strong parentName="h3">{`Pattern Updates`}</strong>{` `}<br />{` `}<br /></h3>
        <ul>
          <li parentName="ul">
            <br />
          </li>
        </ul>
      </AccordionItem>
      <AccordionItem title="20 August 2024" mdxType="AccordionItem">
        <h3><strong parentName="h3">{`Content`}</strong></h3>
        <p><strong parentName="p">{`New`}</strong>{`: `}<inlineCode parentName="p">{`Design / Principle`}</inlineCode>{` page now available `}<br />{` `}<br /></p>
        <p><strong parentName="p">{`Chore`}</strong>{`: Remove Tribe & Ecosystem section in `}<inlineCode parentName="p">{`Design / Get Started`}</inlineCode>{` page `}<br />{` `}<br /></p>
        <p><strong parentName="p">{`Fix`}</strong>{`: Table of Contents overlapping issue while scrolling to the top `}<br /></p>
        <h3><strong parentName="h3">{`Component Design`}</strong>{` `}<br />{` `}<br /></h3>
        <ul>
          <li parentName="ul">
            <br />
          </li>
        </ul>
        <h3><strong parentName="h3">{`Pattern Updates`}</strong>{` `}<br />{` `}<br /></h3>
        <ul>
          <li parentName="ul">
            <br />
          </li>
        </ul>
      </AccordionItem>
      <AccordionItem title="9 August 2024" mdxType="AccordionItem">
        <h3><strong parentName="h3">{`Content`}</strong></h3>
        <p><strong parentName="p">{`New`}</strong>{`: Android Compose for Legion is now Available! `}<br />{` `}<br /></p>
        <p><strong parentName="p">{`New`}</strong>{`: `}<inlineCode parentName="p">{`Color System`}</inlineCode>{` Foundation is now available for Android Compose `}<br />{` `}<br /></p>
        <p><strong parentName="p">{`New`}</strong>{`: `}<inlineCode parentName="p">{`Elevation`}</inlineCode>{` Foundation is now available for Android Compose `}<br />{` `}<br /></p>
        <p><strong parentName="p">{`New`}</strong>{`: `}<inlineCode parentName="p">{`Radius`}</inlineCode>{` Foundation is now available for Android Compose `}<br />{` `}<br /></p>
        <p><strong parentName="p">{`New`}</strong>{`: `}<inlineCode parentName="p">{`Spacer`}</inlineCode>{` Foundation is now available for Android Compose `}<br />{` `}<br /></p>
        <p><strong parentName="p">{`New`}</strong>{`: `}<inlineCode parentName="p">{`Typography`}</inlineCode>{` Foundation is now available for Android Compose `}<br />{` `}<br /></p>
        <p><strong parentName="p">{`Update`}</strong>{`: Optimizing Legion Site repository. Includes code and folder restructure, removing unused components, and a lot of asset management `}<br /></p>
        <h3><strong parentName="h3">{`Component Design`}</strong>{` `}<br />{` `}<br /></h3>
        <ul>
          <li parentName="ul">
            <br />
          </li>
        </ul>
        <h3><strong parentName="h3">{`Pattern Updates`}</strong>{` `}<br />{` `}<br /></h3>
        <ul>
          <li parentName="ul">
            <br />
          </li>
        </ul>
      </AccordionItem>
      <AccordionItem title="15 July 2024" mdxType="AccordionItem">
        <h3><strong parentName="h3">{`Component Design`}</strong>{` `}<br />{` `}<br /></h3>
        <p><strong parentName="p">{`Updated`}</strong>{`: Change structure, implementation component properties, implementation nested component, for `}<inlineCode parentName="p">{`Breadcrumb`}</inlineCode>{` `}<br />{` `}<br /></p>
        <p><strong parentName="p">{`Updated`}</strong>{`: Change structure, implementation component properties, implementation nested component, for `}<inlineCode parentName="p">{`DatePicker`}</inlineCode>{` `}<br />{` `}<br /></p>
        <p><strong parentName="p">{`Updated`}</strong>{`: Change structure, implementation component properties, implementation nested component, for `}<inlineCode parentName="p">{`Dropdown`}</inlineCode>{` `}<br />{` `}<br /></p>
        <p><strong parentName="p">{`Updated`}</strong>{`: Change structure, implementation component properties, implementation nested component, for `}<inlineCode parentName="p">{`Modal`}</inlineCode>{` `}<br />{` `}<br /></p>
        <p><strong parentName="p">{`Updated`}</strong>{`: Change structure, implementation component properties, implementation nested component, for `}<inlineCode parentName="p">{`Navigation`}</inlineCode>{` `}<br />{` `}<br /></p>
        <p><strong parentName="p">{`Updated`}</strong>{`: Change structure, implementation component properties, implementation nested component, for `}<inlineCode parentName="p">{`Pagination`}</inlineCode>{` `}<br />{` `}<br /></p>
        <p><strong parentName="p">{`Updated`}</strong>{`: Change structure, implementation component properties, implementation nested component, for `}<inlineCode parentName="p">{`PinInput`}</inlineCode>{` `}<br />{` `}<br /></p>
        <p><strong parentName="p">{`Updated`}</strong>{`: Change structure, implementation component properties, implementation nested component, for `}<inlineCode parentName="p">{`ProgressCircle`}</inlineCode>{` `}<br />{` `}<br /></p>
        <p><strong parentName="p">{`Updated`}</strong>{`: Change structure, implementation component properties, implementation nested component, for `}<inlineCode parentName="p">{`Rating`}</inlineCode>{` `}<br />{` `}<br /></p>
        <p><strong parentName="p">{`Updated`}</strong>{`: Change structure, implementation component properties, implementation nested component, for `}<inlineCode parentName="p">{`Sidebar`}</inlineCode>{` `}<br />{` `}<br /></p>
        <p><strong parentName="p">{`Updated`}</strong>{`: Change structure, implementation component properties, implementation nested component, for `}<inlineCode parentName="p">{`Slider`}</inlineCode>{` `}<br />{` `}<br /></p>
        <p><strong parentName="p">{`Updated`}</strong>{`: Change structure, implementation component properties, implementation nested component, for `}<inlineCode parentName="p">{`Table`}</inlineCode>{` `}<br />{` `}<br /></p>
        <p><strong parentName="p">{`Updated`}</strong>{`: Change structure for `}<inlineCode parentName="p">{`Tabs`}</inlineCode>{` `}<br />{` `}<br /></p>
        <p><strong parentName="p">{`Updated`}</strong>{`: Change structure, implementation component properties, implementation nested component, for `}<inlineCode parentName="p">{`Tree`}</inlineCode>{` `}<br />{` `}<br /></p>
        <p><strong parentName="p">{`Updated`}</strong>{`: Change structure, implementation component properties, implementation nested component, for `}<inlineCode parentName="p">{`Upload`}</inlineCode>{` `}<br />{` `}<br /></p>
        <h3><strong parentName="h3">{`Pattern Updates`}</strong>{` `}<br />{` `}<br /></h3>
        <h4><strong parentName="h4">{`Pattern Dashboard`}</strong>{` `}<br />{` `}<br /></h4>
        <p><strong parentName="p">{`Updated`}</strong>{`: Modify tab structure in Website’s Dashboard Pattern `}<br />{` `}<br /></p>
        <p><strong parentName="p">{`Updated`}</strong>{`: Add new Data Visualization tab in Website’s Dashboard Pattern `}<br />{` `}<br /></p>
        <p><strong parentName="p">{`Updated`}</strong>{`: Moved Data Visualization information from Guideline tab to Data Visualization tab `}<br />{` `}<br /></p>
        <p><strong parentName="p">{`Updated`}</strong>{`: Split Data Visualization category into 4 new categories; Comparison, Composition, Relationship, and Distribution `}<br />{` `}<br /></p>
        <p><strong parentName="p">{`Updated`}</strong>{`: Change charts composition into the new 4 categories `}<br />{` `}<br /></p>
        <p><strong parentName="p">{`Updated`}</strong>{`: Improve copy of charts in Data Visualization tab `}<br />{` `}<br /></p>
        <p><strong parentName="p">{`Updated`}</strong>{`: Improve use case according to Telkom’s Dashboard `}<br />{` `}<br /></p>
        <p><strong parentName="p">{`Updated`}</strong>{`: Change naming categories into new 4 categories on Live Preview website `}<br />{` `}<br /></p>
        <p><strong parentName="p">{`Updated`}</strong>{`: Change charts composition into new 4 categories on Live Preview website `}<br />{` `}<br /></p>
        <h4><strong parentName="h4">{`Pattern User Management`}</strong>{` `}<br />{` `}<br /></h4>
        <p><strong parentName="p">{`Updated`}</strong>{`: Add use case for Access Control List (ACL) `}<br />{` `}<br /></p>
        <p><strong parentName="p">{`Updated`}</strong>{`: Add use case for Role Based Access Control (RBAC) `}<br />{` `}<br /></p>
        <p><strong parentName="p">{`Updated`}</strong>{`: Add guideline for User Management case `}<br />{` `}<br /></p>
        <p><strong parentName="p">{`Updated`}</strong>{`: Add guideline for Role Management case `}<br />{` `}<br /></p>
        <p><strong parentName="p">{`Updated`}</strong>{`: Add guideline for Permission Management `}<br />{` `}<br /></p>
        <p><strong parentName="p">{`Updated`}</strong>{`: Improve copy of use case in ACL and RBAC `}<br />{` `}<br /></p>
        <p><strong parentName="p">{`Updated`}</strong>{`: Change design comparison model Guideline `}<br />{` `}<br /></p>
        <p><strong parentName="p">{`Updated`}</strong>{`: Change design level of permission Guideline `}<br />{` `}<br /></p>
        <h4><strong parentName="h4">{`Pattern Login & Register (Website)`}</strong>{` `}<br />{` `}<br /></h4>
        <p><strong parentName="p">{`Updated`}</strong>{`: Add double check for Terms and Conditions and Privacy & Policy in Register (Sign Up) page - (Applied in all use cases) `}<br />{` `}<br /></p>
        <p><strong parentName="p">{`Updated`}</strong>{`: Add Terms and Conditions page `}<br />{` `}<br /></p>
        <p><strong parentName="p">{`Updated`}</strong>{`: Add Privacy and Policy page `}<br />{` `}<br /></p>
        <p><strong parentName="p">{`Updated`}</strong>{`: Add new information of UU PDP in Guideline page `}<br />{` `}<br /></p>
        <p><strong parentName="p">{`Updated`}</strong>{`: Add new information section about compliance in UU PDP (in Guideline: Website & Mobile page) `}<br />{` `}<br /></p>
        <h4><strong parentName="h4">{`Pattern Login & Register (Mobile)`}</strong>{` `}<br />{` `}<br /></h4>
        <p><strong parentName="p">{`Updated`}</strong>{`: Add double check for Terms and Conditions and Privacy & Policy in Register (Sign Up) page - (Applied in all use cases) `}<br />{` `}<br /></p>
        <p><strong parentName="p">{`Updated`}</strong>{`: Add Terms and Conditions page `}<br />{` `}<br /></p>
        <p><strong parentName="p">{`Updated`}</strong>{`: Add Privacy and Policy page `}<br />{` `}<br /></p>
        <p><strong parentName="p">{`Updated`}</strong>{`: Add new information of UU PDP in Guideline page `}<br />{` `}<br /></p>
        <p><strong parentName="p">{`Updated`}</strong>{`: Add new information section about compliance in UU PDP (in Guideline: Website & Mobile page) `}<br />{` `}<br /></p>
      </AccordionItem>
      <AccordionItem title="21 November 2023" mdxType="AccordionItem">
        <h3><strong parentName="h3">{`Website Updates`}</strong>{` `}<br />{` `}<br /></h3>
        <p><strong parentName="p">{`Bugfix`}</strong>{`: Missing fixed menu on scroll`}</p>
        <p><strong parentName="p">{`New`}</strong>{`: Guideline page on `}<inlineCode parentName="p">{`Pin Input`}</inlineCode>{`, `}<inlineCode parentName="p">{`Rating`}</inlineCode>{`, and `}<inlineCode parentName="p">{`Tree`}</inlineCode>{` components`}</p>
        <p><strong parentName="p">{`New`}</strong>{`: New Graphicon page`}</p>
        <p><strong parentName="p">{`New`}</strong>{`: Added new theme selection`}</p>
        <p><strong parentName="p">{`Bugfix`}</strong>{`: Fixed `}<inlineCode parentName="p">{`baseWhite`}</inlineCode>{` to `}<inlineCode parentName="p">{`basewhite`}</inlineCode></p>
        <p><strong parentName="p">{`Bugfix`}</strong>{`: Fixed `}<inlineCode parentName="p">{`baseBlack`}</inlineCode>{` to `}<inlineCode parentName="p">{`baseblack`}</inlineCode></p>
        <p><strong parentName="p">{`Bugfix`}</strong>{`: Fixed unique default ID and adds props name to `}<inlineCode parentName="p">{`Textarea`}</inlineCode>{` and `}<inlineCode parentName="p">{`Textfield`}</inlineCode>{` components`}</p>
        <p><strong parentName="p">{`Bugfix`}</strong>{`: Added props name to `}<inlineCode parentName="p">{`Switch`}</inlineCode>{` component`}</p>
        <p><strong parentName="p">{`Update`}</strong>{`: Remove props `}<inlineCode parentName="p">{`show`}</inlineCode>{` improve toogle with DOM on `}<inlineCode parentName="p">{`Dropdown`}</inlineCode>{` component`}</p>
        <p><strong parentName="p">{`Update`}</strong>{`: Change `}<inlineCode parentName="p">{`setTimeOut`}</inlineCode>{` to `}<inlineCode parentName="p">{`setInterval`}</inlineCode></p>
        <h3><strong parentName="h3">{`Development Library Updates`}</strong>{` `}<br /></h3>
        <h4><strong parentName="h4">{`Legion Phoenix - Release Version: 2.1.1`}</strong>{` `}<br />{` `}<br /></h4>
        <p><strong parentName="p">{`Bugfix`}</strong>{`: Fixed `}<inlineCode parentName="p">{`baseWhite`}</inlineCode>{` to `}<inlineCode parentName="p">{`basewhite`}</inlineCode></p>
        <p><strong parentName="p">{`Bugfix`}</strong>{`: Fixed `}<inlineCode parentName="p">{`baseBlack`}</inlineCode>{` to `}<inlineCode parentName="p">{`baseblack`}</inlineCode></p>
        <p><strong parentName="p">{`Bugfix`}</strong>{`: Fixed unique default ID and adds props name to `}<inlineCode parentName="p">{`Textarea`}</inlineCode>{` and `}<inlineCode parentName="p">{`Textfield`}</inlineCode>{` components`}</p>
        <p><strong parentName="p">{`Bugfix`}</strong>{`: Added props name to `}<inlineCode parentName="p">{`Switch`}</inlineCode>{` component`}</p>
        <p><strong parentName="p">{`Update`}</strong>{`: Remove props `}<inlineCode parentName="p">{`show`}</inlineCode>{` improve toogle with DOM on `}<inlineCode parentName="p">{`Dropdown`}</inlineCode>{` component`}</p>
        <p><strong parentName="p">{`Update`}</strong>{`: Change `}<inlineCode parentName="p">{`setTimeOut`}</inlineCode>{` to `}<inlineCode parentName="p">{`setInterval`}</inlineCode></p>
        <h3><strong parentName="h3">{`Design Library Updates`}</strong>{` `}<br />{` `}<br /></h3>
        <ul>
          <li parentName="ul">
            <br />
          </li>
        </ul>
      </AccordionItem>
      <AccordionItem title="18 August 2023" mdxType="AccordionItem">
        <h3><strong parentName="h3">{`Website Updates`}</strong>{` `}<br />{` `}<br /></h3>
        <p><strong parentName="p">{`Website Documentation - Release Version : 2.68.37`}</strong>{` `}<br />{` `}<br /></p>
        <p><strong parentName="p">{`New`}</strong>{`: Login and Register New Cases `}<br />{` `}<br /></p>
        <p><strong parentName="p">{`New`}</strong>{`: `}<inlineCode parentName="p">{`BPOM`}</inlineCode>{` Theme `}<br />{` `}<br /></p>
        <p><strong parentName="p">{`New`}</strong>{`: Feedback Hover Section `}<br />{` `}<br /></p>
        <p><strong parentName="p">{`Update`}</strong>{`: Login and Register Pattern Guideline `}<br />{` `}<br /></p>
        <p><strong parentName="p">{`Update`}</strong>{`: Download Modal for Legion Android Prototype `}<br />{` `}<br /></p>
        <p><strong parentName="p">{`Update`}</strong>{`: Components’ and Patterns’ Figma and Code Resources `}<br />{` `}<br /></p>
        <p><strong parentName="p">{`Update`}</strong>{`: Add color information on `}<inlineCode parentName="p">{`Chip`}</inlineCode>{` `}<br />{` `}<br /></p>
        <p><strong parentName="p">{`Update`}</strong>{`: Add `}<inlineCode parentName="p">{`onKeyDown`}</inlineCode>{` prop in `}<inlineCode parentName="p">{`Textfield`}</inlineCode>{` `}<br />{` `}<br /></p>
        <p><strong parentName="p">{`Update`}</strong>{`: Add Capability Change Custom Color In Android `}<inlineCode parentName="p">{`Button`}</inlineCode>{` Component `}<br />{` `}<br /></p>
        <p><strong parentName="p">{`Update`}</strong>{`: Add Capability to Hide Icon on Android `}<inlineCode parentName="p">{`Alert`}</inlineCode>{` `}<br />{` `}<br /></p>
        <p><strong parentName="p">{`Update`}</strong>{`: Add Capability to set error without label `}<br />{` `}<br /></p>
        <p><strong parentName="p">{`Update`}</strong>{`: New Android `}<inlineCode parentName="p">{`Modal`}</inlineCode>{` Component `}<br />{` `}<br /></p>
        <p><strong parentName="p">{`Update`}</strong>{`: New Android `}<inlineCode parentName="p">{`Tooltip`}</inlineCode>{` Component `}<br />{` `}<br /></p>
        <p><strong parentName="p">{`Update`}</strong>{`: Add Capability to Load File in LgnPhotoField `}<br />{` `}<br /></p>
        <p><strong parentName="p">{`Bugfix`}</strong>{`: Overwrite `}<inlineCode parentName="p">{`className`}</inlineCode>{` behaviour `}<br />{` `}<br /></p>
        <p><strong parentName="p">{`Bugfix`}</strong>{`: Various bugs fixing `}<br />{` `}<br /></p>
        <p><strong parentName="p">{`Bugfix`}</strong>{`: Text Allignment on `}<inlineCode parentName="p">{`Button`}</inlineCode>{` Label `}<br />{` `}<br /></p>
        <p><strong parentName="p">{`Bugfix`}</strong>{`: Space Icon in `}<inlineCode parentName="p">{`Button`}</inlineCode>{` `}<br />{` `}<br /></p>
        <h3><strong parentName="h3">{`Development Library Updates`}</strong>{` `}<br /></h3>
        <h4><strong parentName="h4">{`Legion Phoenix - Release Version: 2.0.0-beta.28`}</strong>{` `}<br />{` `}<br /></h4>
        <p><strong parentName="p">{`New`}</strong>{`: Component ProgressbarCirlce `}<br />{` `}<br /></p>
        <p><strong parentName="p">{`Update`}</strong>{`: Design `}<inlineCode parentName="p">{`datepicker time`}</inlineCode>{` component `}<br />{` `}<br /></p>
        <p><strong parentName="p">{`Update`}</strong>{`: Tooltip component `}<br />{` `}<br /></p>
        <p><strong parentName="p">{`Update`}</strong>{`: Add color information on `}<inlineCode parentName="p">{`Chip`}</inlineCode>{` `}<br />{` `}<br /></p>
        <p><strong parentName="p">{`Update`}</strong>{`: Add `}<inlineCode parentName="p">{`onKeyDown`}</inlineCode>{` prop in `}<inlineCode parentName="p">{`Textfield`}</inlineCode>{` `}<br />{` `}<br /></p>
        <p><strong parentName="p">{`Bugfix`}</strong>{`: Overwrite `}<inlineCode parentName="p">{`className`}</inlineCode>{` behaviour `}<br />{` `}<br /></p>
        <p><strong parentName="p">{`Bugfix`}</strong>{`: Various bugs fixing `}<br />{` `}<br /></p>
        <p><strong parentName="p">{`Bugfix`}</strong>{`: Put back custom props to all component `}<br />{` `}<br /></p>
        <h3><strong parentName="h3">{`Design Library Updates`}</strong>{` `}<br />{` `}<br /></h3>
        <p><strong parentName="p">{`Website Pattern and UI Kit - Release Version : 2.0.2`}</strong>{` `}<br />{` `}<br /></p>
        <p><strong parentName="p">{`Updated`}</strong>{`: Change structure, implementation component properties, implementation nested component, for Accordion `}<br />{` `}<br /></p>
        <p><strong parentName="p">{`Updated`}</strong>{`: Change structure, implementation component properties, implementation nested component, for Alert `}<br />{` `}<br /></p>
        <p><strong parentName="p">{`Updated`}</strong>{`: Change structure, implementation component properties, implementation nested component, for Anchor Text `}<br />{` `}<br /></p>
        <p><strong parentName="p">{`Updated`}</strong>{`: Change structure, implementation component properties, implementation nested component, for Avatar `}<br />{` `}<br /></p>
        <p><strong parentName="p">{`Updated`}</strong>{`: Change structure, implementation component properties, implementation nested component, for Badge `}<br />{` `}<br /></p>
        <p><strong parentName="p">{`Updated`}</strong>{`: Change structure, implementation component properties, implementation nested component, for Button `}<br />{` `}<br /></p>
        <p><strong parentName="p">{`Updated`}</strong>{`: Change structure, implementation component properties, implementation nested component, for Card `}<br />{` `}<br /></p>
        <p><strong parentName="p">{`Updated`}</strong>{`: Change structure, implementation component properties, implementation nested component, for Chips `}<br />{` `}<br /></p>
        <p><strong parentName="p">{`Updated`}</strong>{`: Change structure, implementation component properties, implementation nested component, for Divider `}<br />{` `}<br /></p>
        <p><strong parentName="p">{`Updated`}</strong>{`: Change structure for Images `}<br />{` `}<br /></p>
        <p><strong parentName="p">{`Updated`}</strong>{`: Change structure, implementation component properties, implementation nested component, for Progress Bar `}<br />{` `}<br /></p>
        <p><strong parentName="p">{`Updated`}</strong>{`: Change structure, implementation component properties, implementation nested component, for Radio Button `}<br />{` `}<br /></p>
        <p><strong parentName="p">{`Updated`}</strong>{`: Change structure, implementation component properties, implementation nested component, for Select Field `}<br />{` `}<br /></p>
        <p><strong parentName="p">{`Updated`}</strong>{`: Change structure for Spinner `}<br />{` `}<br /></p>
        <p><strong parentName="p">{`Updated`}</strong>{`: Change structure, implementation component properties, implementation nested component, for Stepper `}<br />{` `}<br /></p>
        <p><strong parentName="p">{`Updated`}</strong>{`: Change structure, implementation component properties, implementation nested component, for Snackbar `}<br />{` `}<br /></p>
        <p><strong parentName="p">{`Updated`}</strong>{`: Change structure, implementation component properties, implementation nested component, for Switch `}<br />{` `}<br /></p>
        <p><strong parentName="p">{`Updated`}</strong>{`: Change structure, implementation component properties, implementation nested component, for Tooltip `}<br />{` `}<br /></p>
        <p><strong parentName="p">{`Updated`}</strong>{`: Change structure, implementation component properties, implementation nested component, for Text Area `}<br />{` `}<br /></p>
        <p><strong parentName="p">{`Updated`}</strong>{`: Change structure, implementation component properties, implementation nested component, for Text Fields `}<br />{` `}<br /></p>
        <p><strong parentName="p">{`Deprecated`}</strong>{`: Temporarily removed component Carousel for bug fixes to repair structural components and will be released again in the next version `}<br />{` `}<br /></p>
        <p><strong parentName="p">{`Deprecated`}</strong>{`: Temporarily removed component Date Picker for bug fixes to repair structural components and will be released again in the next version `}<br />{` `}<br /></p>
        <p><strong parentName="p">{`Deprecated`}</strong>{`: Temporarily removed component Drop Down for bug fixes to repair structural components and will be released again in the next version `}<br />{` `}<br /></p>
        <p><strong parentName="p">{`Deprecated`}</strong>{`: Temporarily removed component Mega Menu for bug fixes to repair structural components and will be released again in the next version `}<br />{` `}<br /></p>
        <p><strong parentName="p">{`Deprecated`}</strong>{`: Temporarily removed component Modal for bug fixes to repair structural components and will be released again in the next version `}<br />{` `}<br /></p>
        <p><strong parentName="p">{`Deprecated`}</strong>{`: Temporarily removed component Navigation for bug fixes to repair structural components and will be released again in the next version `}<br />{` `}<br /></p>
        <p><strong parentName="p">{`Deprecated`}</strong>{`: Temporarily removed component Pagination for bug fixes to repair structural components and will be released again in the next version `}<br />{` `}<br /></p>
        <p><strong parentName="p">{`Deprecated`}</strong>{`: Temporarily removed component Slider for bug fixes to repair structural components and will be released again in the next version `}<br />{` `}<br /></p>
        <p><strong parentName="p">{`Deprecated`}</strong>{`: Temporarily removed component Side Bar for bug fixes to repair structural components and will be released again in the next version `}<br />{` `}<br /></p>
        <p><strong parentName="p">{`Deprecated`}</strong>{`: Temporarily removed component Table for bug fixes to repair structural components and will be released again in the next version `}<br />{` `}<br /></p>
        <p><strong parentName="p">{`Deprecated`}</strong>{`: Temporarily removed component Tabs for bug fixes to repair structural components and will be released again in the next version `}<br />{` `}<br /></p>
      </AccordionItem>
      <AccordionItem title="21 June 2023" mdxType="AccordionItem">
        <h3><strong parentName="h3">{`Website Updates`}</strong>{` `}<br /></h3>
        <p><strong parentName="p">{`New`}</strong>{`: Embedded Storybook to interact with components in real-time `}<br />{` `}<br /></p>
        <p><strong parentName="p">{`New`}</strong>{`: Dashboard pattern `}<br />{` `}<br /></p>
        <p><strong parentName="p">{`New`}</strong>{`: Feature to see component’s test status, Figma and code resources of the patterns `}<br />{` `}<br /></p>
        <p><strong parentName="p">{`New`}</strong>{`: Illustration page `}<br />{` `}<br /></p>
        <p><strong parentName="p">{`New`}</strong>{`: Adds Illustrator menu on Home page `}<br />{` `}<br /></p>
        <p><strong parentName="p">{`New`}</strong>{`: Adds Squads’ font usage on the Typography documentation page `}<br />{` `}<br /></p>
        <p><strong parentName="p">{`Updates`}</strong>{`: Change structure of tab in website pattern dashboard `}<br />{` `}<br /></p>
        <p><strong parentName="p">{`Updates`}</strong>{`: Add new tab of data visualization in website pattern dashboard `}<br />{` `}<br /></p>
        <p><strong parentName="p">{`Updates`}</strong>{`: Moved data visualization information from guideline tab to data visualization tab `}<br />{` `}<br /></p>
        <p><strong parentName="p">{`Updates`}</strong>{`: Change data visualization category into 4 new categories; Comparison, Composition, Relationship, and Distribution `}<br />{` `}<br /></p>
        <p><strong parentName="p">{`Updates`}</strong>{`: Change charts composition into the new 4 categories `}<br />{` `}<br /></p>
        <p><strong parentName="p">{`Updates`}</strong>{`: Improve copy of charts in data visualization tab `}<br />{` `}<br /></p>
        <p><strong parentName="p">{`Updates`}</strong>{`: Improve use case according to Telkom’s dashboard `}<br />{` `}<br /></p>
        <p><strong parentName="p">{`Updates`}</strong>{`: Change naming categories into the new 4 categories on live preview website `}<br />{` `}<br /></p>
        <p><strong parentName="p">{`Updates`}</strong>{`: Change charts composition into the new 4 categories on live preview website `}<br />{` `}<br /></p>
        <p><strong parentName="p">{`Updates`}</strong>{`: Design’s Access Request information `}<br />{` `}<br /></p>
        <p><strong parentName="p">{`Updates`}</strong>{`: Development’s documentations Installation and Configuration `}<br />{` `}<br /></p>
        <p><strong parentName="p">{`Updates`}</strong>{`: Contribute and Request page `}<br />{` `}<br /></p>
        <p><strong parentName="p">{`Updates`}</strong>{`: Foundation’s documentations updates (Color System, Typography, Spacer, Radius, Elevation) `}<br />{` `}<br /></p>
        <p><strong parentName="p">{`Updates`}</strong>{`: Release Notes page for each platform `}<br />{` `}<br /></p>
        <p><strong parentName="p">{`Update`}</strong>{`: Overview cards on Components, Content, and Patterns `}<br />{` `}<br /></p>
        <p><strong parentName="p">{`Update`}</strong>{`: `}<inlineCode parentName="p">{`Accordion`}</inlineCode>{`, `}<inlineCode parentName="p">{`Alert`}</inlineCode>{`, `}<inlineCode parentName="p">{`Anchor`}</inlineCode>{`, `}<inlineCode parentName="p">{`Avatar`}</inlineCode>{`, `}<inlineCode parentName="p">{`Badge`}</inlineCode>{`, `}<inlineCode parentName="p">{`Box`}</inlineCode>{`, `}<inlineCode parentName="p">{`Button`}</inlineCode>{`, `}<inlineCode parentName="p">{`Card`}</inlineCode>{`, `}<inlineCode parentName="p">{`Checkbox`}</inlineCode>{`, `}<inlineCode parentName="p">{`Chips`}</inlineCode>{`, `}<inlineCode parentName="p">{`Date Picker`}</inlineCode>{`, `}<inlineCode parentName="p">{`Divider`}</inlineCode>{`, `}<inlineCode parentName="p">{`Dropdown`}</inlineCode>{`, `}<inlineCode parentName="p">{`Flex`}</inlineCode>{`, `}<inlineCode parentName="p">{`Image`}</inlineCode>{`, `}<inlineCode parentName="p">{`Modal`}</inlineCode>{`, `}<inlineCode parentName="p">{`Navigation Bar`}</inlineCode>{`, `}<inlineCode parentName="p">{`Radio Button`}</inlineCode>{`, `}<inlineCode parentName="p">{`Snackbar`}</inlineCode>{`, `}<inlineCode parentName="p">{`Select Field`}</inlineCode>{`, `}<inlineCode parentName="p">{`Sidebar`}</inlineCode>{`, `}<inlineCode parentName="p">{`Spinner`}</inlineCode>{`, `}<inlineCode parentName="p">{`Stepper`}</inlineCode>{`, `}<inlineCode parentName="p">{`Switch`}</inlineCode>{`, `}<inlineCode parentName="p">{`Tab Bar`}</inlineCode>{`, `}<inlineCode parentName="p">{`Table`}</inlineCode>{`, `}<inlineCode parentName="p">{`Textarea`}</inlineCode>{`, `}<inlineCode parentName="p">{`Textfield`}</inlineCode>{`, and `}<inlineCode parentName="p">{`Tooltip`}</inlineCode>{` component < `}<br />{` `}<br /></p>
        <p><strong parentName="p">{`Update`}</strong>{`: Add Ime Options Capability on Text Field Component `}<br />{` `}<br /></p>
        <p><strong parentName="p">{`Update`}</strong>{`: Add Compability Snackbar on Bottom Sheet `}<br />{` `}<br /></p>
        <p><strong parentName="p">{`Update`}</strong>{`: New Component Navigation Bar `}<br />{` `}<br /></p>
        <p><strong parentName="p">{`Update`}</strong>{`: Revamp Multi photo field to LgnPhotoField `}<br />{` `}<br /></p>
        <p><strong parentName="p">{`Bug Fix`}</strong>{`: Space on Small Button when Icon visible only one `}<br />{` `}<br /></p>
        <p><strong parentName="p">{`Bug Fix`}</strong>{`: Button Action Snackbar not show properly `}<br />{` `}<br /></p>
        <p><strong parentName="p">{`Bug Fix`}</strong>{`: Image Picker Show on Disabled State on LgnPhotoField `}<br />{` `}<br /></p>
        <p><strong parentName="p">{`Bug Fix`}</strong>{`: Bottom Sheet Peek Height on Tablet Devices `}<br />{` `}<br /></p>
        <p><strong parentName="p">{`Bug Fix`}</strong>{`: Bottom Sheet Footer Button on Tablet Devices `}<br />{` `}<br /></p>
        <p><strong parentName="p">{`New Theme`}</strong>{`: Added new theme support `}<inlineCode parentName="p">{`Logee Distribution`}</inlineCode>{` `}<br />{` `}<br />{` `}<br /></p>
        <p><strong parentName="p">{`New Theme`}</strong>{`: Added new theme support `}<inlineCode parentName="p">{`Logee Transportation`}</inlineCode>{` `}<br />{` `}<br />{` `}<br /></p>
        <p><strong parentName="p">{`Bug Fix`}</strong>{`: Comments & readme on Switch component `}<br />{` `}<br /></p>
        <p><strong parentName="p">{`Update`}</strong>{`: Support Autocapitalization on LGNTextField `}<br />{` `}<br /></p>
        <p><strong parentName="p">{`Bug Fix`}</strong>{`: missing textfield placeholder `}<br />{` `}<br /></p>
        <p><strong parentName="p">{`Bug Fix`}</strong>{`: Fix broken textfield’s LGNContentModel inaccessible from all themes `}<br />{` `}<br /></p>
        <p><strong parentName="p">{`Bug Fix`}</strong>{`: Fix soft button showProgressView tint color, and open all properties of all buttons `}<br />{` `}<br /></p>
        <p><strong parentName="p">{`New`}</strong>{`: Add secure text support to LGNTextField `}<br />{` `}<br /></p>
        <h3><strong parentName="h3">{`Development Library Updates`}</strong>{` `}<br /></h3>
        <h4><strong parentName="h4">{`Legion Phoenix`}</strong>{` `}<br /></h4>
        <p>{`Legion Phoenix (will be addressed as Legion v2 from here on out), is the updated version of the Legion UI, has now been released! 🎉`}</p>
        <p>{`In this version, we have improved some aspects of the library from the previous one in order to be able to deliver your needs better to you.`}</p>
        <p>{`One of the biggest changes in v2 in our vision was to ditch Theme UI and went through to the root of Emotion for better development process as default styling solution in Legion.`}</p>
        <p>{`By eliminating Theme UI, we are confident to say that we can produce better components, more efficient, more straightforward, not being limited to Theme UI’s rules, and the big elephant in the room is that we can trim a lot of production bundle size. This will be addressed in other section of the article.`}</p>
        <h4><strong parentName="h4">{`Why Should You Migrate`}</strong></h4>
        <p>{`Legion v2 includes many bug fixes and improvement over v1.`}</p>
        <p>{`Some of the changes we proudly could approve is having the core of Legion itself easier now, to customize your components and styling directly instead of doing parent and children destructurization.`}</p>
        <p>{`Other than that, Legion v2 starts to adapt what’s been the latest standard of React that is the latest React 18, in order to use all the advantages React 18 has to offer surely you need to migrate.`}</p>
        <h4><strong parentName="h4">{`Further changelogs regarding v2`}</strong></h4>
        <p>{`Here are some consideration to look at when we tried to document what has been the common issues from Legion v1, what’s changed and how do we resolve those problems. `}<br /><br /></p>
        <h5><strong parentName="h5">{`Common issues`}</strong></h5>
        <ul>
          <li parentName="ul">
            <p parentName="li">{`Heavy bundle size causing performance issues to consider implementing it, some of it caused by not implementing tree shaking method in its packaging, unoptimized component production, and file structure as a general`}</p>
          </li>
          <li parentName="ul">
            <p parentName="li">{`The difficult to override in certain theme styling without needing to update the package as a whole, also the difficult to customize components further`}</p>
          </li>
          <li parentName="ul">
            <p parentName="li">{`Package caching, happens often when there’s an update presented but since the package is cached the update isn’t as instant. The alternative solution would be reinstalling the whole package and have another package-lock generated`}</p>
          </li>
          <li parentName="ul">
            <p parentName="li">{`Temporary Service Outage from Nexus preventing our users to develop their app, ranging from the Nexus itself running out of space, authentication issues, etc.`}</p>
          </li>
          <li parentName="ul">
            <p parentName="li">{`There is no playground for our users to preview components when developing or trying out components, our temporary solution was to make a separate React App and then link the legion-ui package which is not the most efficient thing`}</p>
          </li>
          <li parentName="ul">
            <p parentName="li">{`There’s no development build, a feature would get released to the production build’s package immediately and bugs are often hotfixed when it’s live`}</p>
          </li>
          <li parentName="ul">
            <p parentName="li">{`Inconsistent documentation writing with less strict Quality Check and communication between our Front End Engineer and Documentation Engineer `}<br />{` `}<br /></p>
          </li>
        </ul>
        <h5><strong parentName="h5">{`What’s planned and what’s changing`}</strong></h5>
        <ul>
          <li parentName="ul">
            <p parentName="li">{`We are now in Telkom’s Playcourt area, which means it will be now easier for you to contribute and report your issues directly to us. Everyone is welcome to contribute, with just a little bit of README and `}<a parentName="p" {...{
                "href": "https://gitlab.playcourt.id/telkomdev/legion-phoenix/-/blob/master/CONTRIBUTING.md"
              }}><strong parentName="a">{`Contribution Guideline`}</strong></a>{` reading, you can start helping us out!`}</p>
          </li>
          <li parentName="ul">
            <p parentName="li">{`Removing `}<a parentName="p" {...{
                "href": "https://www.npmjs.com/package/react-feather"
              }}><strong parentName="a">{`react-feather`}</strong></a>{` as our icon library to reduce bundle size, and our users can be more flexible with their iconography choices. We are planing to still provide our in house icon library in further development with less bundle size and better implementation`}</p>
          </li>
          <li parentName="ul">
            <p parentName="li">{`Removing `}<a parentName="p" {...{
                "href": "https://theme-ui.com/"
              }}><strong parentName="a">{`Theme UI`}</strong></a>{` as styling dependencies and decided to went with `}<a parentName="p" {...{
                "href": "https://emotion.sh/docs/introduction"
              }}><strong parentName="a">{`Emotion`}</strong></a>{` to reduce bundle size, and simplify our development process. As for users, it will be easier to customize your components too since you don’t have to deal with Theme UI and will be directly customizing from Emotion`}</p>
          </li>
          <li parentName="ul">
            <p parentName="li">{`Modular and tree-shaking support to reduce bundle size, and users can be more flexible with what components are they using`}</p>
          </li>
          <li parentName="ul">
            <p parentName="li">{`Changing the Theming schema from having to package installation, to an API based theming. This will improve real-time theming experience without needing to switch theme version everytime there is an update from Figma`}</p>
          </li>
          <li parentName="ul">
            <p parentName="li">{`Supports responsive block and polymorphism for some of our components (Text, Flex, Block)`}</p>
          </li>
          <li parentName="ul">
            <p parentName="li">{`Supports better on React’s ref including Text, List, Checkbox, Radio, Textfield, Textarea, and Button`}</p>
          </li>
          <li parentName="ul">
            <p parentName="li">{`Renaming our package from `}<inlineCode parentName="p">{`legion-ui`}</inlineCode>{` to `}<inlineCode parentName="p">{`@legion-ui/core`}</inlineCode>{` for easier package scoping, users’ development process, and publishing in our ends`}</p>
          </li>
          <li parentName="ul">
            <p parentName="li">{`Implementing `}<a parentName="p" {...{
                "href": "https://storybook.js.org"
              }}><strong parentName="a">{`Storybook`}</strong></a>{` for testing purpose, component previews and documentations, and storybook embeds in our website. This is to eliminate some cases where components’ styling aren’t aligned properly in what’s in designer’s Figma`}</p>
          </li>
          <li parentName="ul">
            <p parentName="li">{`Better Typescript experience, especially in interface and type writing`}</p>
          </li>
        </ul>
        <p>{`Now that it’s all there, go over to our `}<a parentName="p" {...{
            "href": "/development/installation/website"
          }}><strong parentName="a">{`installation page`}</strong></a>{` to start using our latest Legion v2 library immediately!`}</p>
        <p><strong parentName="p">{`Website (Legion-UI) - Release Version : 2.0.0`}</strong>{` `}<br /></p>
        <p><strong parentName="p">{`Update`}</strong>{`: `}<inlineCode parentName="p">{`Accordion`}</inlineCode>{`, `}<inlineCode parentName="p">{`Alert`}</inlineCode>{`, `}<inlineCode parentName="p">{`Anchor`}</inlineCode>{`, `}<inlineCode parentName="p">{`Avatar`}</inlineCode>{`, `}<inlineCode parentName="p">{`Badge`}</inlineCode>{`, `}<inlineCode parentName="p">{`Box`}</inlineCode>{`, `}<inlineCode parentName="p">{`Button`}</inlineCode>{`, `}<inlineCode parentName="p">{`Card`}</inlineCode>{`, `}<inlineCode parentName="p">{`Checkbox`}</inlineCode>{`, `}<inlineCode parentName="p">{`Chips`}</inlineCode>{`, `}<inlineCode parentName="p">{`Date Picker`}</inlineCode>{`, `}<inlineCode parentName="p">{`Divider`}</inlineCode>{`, `}<inlineCode parentName="p">{`Dropdown`}</inlineCode>{`, `}<inlineCode parentName="p">{`Flex`}</inlineCode>{`, `}<inlineCode parentName="p">{`Image`}</inlineCode>{`, `}<inlineCode parentName="p">{`Modal`}</inlineCode>{`, `}<inlineCode parentName="p">{`Navigation Bar`}</inlineCode>{`, `}<inlineCode parentName="p">{`Radio Button`}</inlineCode>{`, `}<inlineCode parentName="p">{`Snackbar`}</inlineCode>{`, `}<inlineCode parentName="p">{`Select Field`}</inlineCode>{`, `}<inlineCode parentName="p">{`Sidebar`}</inlineCode>{`, `}<inlineCode parentName="p">{`Spinner`}</inlineCode>{`, `}<inlineCode parentName="p">{`Stepper`}</inlineCode>{`, `}<inlineCode parentName="p">{`Switch`}</inlineCode>{`, `}<inlineCode parentName="p">{`Tab Bar`}</inlineCode>{`, `}<inlineCode parentName="p">{`Table`}</inlineCode>{`, `}<inlineCode parentName="p">{`Textarea`}</inlineCode>{`, `}<inlineCode parentName="p">{`Textfield`}</inlineCode>{`, and `}<inlineCode parentName="p">{`Tooltip`}</inlineCode>{` component `}<br /></p>
        <h3><strong parentName="h3">{`Design Library Updates`}</strong>{` `}<br />{` `}<br /></h3>
        <p><strong parentName="p">{`Website Pattern and UI Kit - Release Version : 1.9.20`}</strong>{` `}<br />{` `}<br /></p>
        <p><strong parentName="p">{`Update`}</strong>{`: Change structure size to master component, change icons, implementation component properties, implementation nested component, add loading state and border adjust radius in Button Component `}<br />{` `}<br /></p>
        <p><strong parentName="p">{`Update`}</strong>{`: Change structure size and variant to master component, implementation component properties, implementation nested component, adjust icons in Checkbox Component `}<br />{` `}<br /></p>
        <p><strong parentName="p">{`Update`}</strong>{`: Change structure size to master component, implementation component properties, implementation nested component, adjust icons in Radio Button Component `}<br />{` `}<br /></p>
        <p><strong parentName="p">{`Update`}</strong>{`: Change structure size to master component, change icons, implementation component properties and implementation nested component in Anchor Text Component `}<br />{` `}<br /></p>
        <p><strong parentName="p">{`Update`}</strong>{`: Change structure size to master component, change icons, implementation component properties and implementation nested component in Chips Component `}<br />{` `}<br /></p>
        <p><strong parentName="p">{`Update`}</strong>{`: Change structure size to master component, change icons, implementation component properties and implementation nested component in Avatar Component `}<br />{` `}<br /></p>
        <p><strong parentName="p">{`Update`}</strong>{`: Change structure size and type to master component, change icons, implementation component properties and implementation nested component in Badge Component `}<br />{` `}<br /></p>
        <p><strong parentName="p">{`Update`}</strong>{`: Change structure size to master component, change icons, reduces base variants, adjust border radius, implementation component properties, add hover state and implementation nested component in Text Field Component `}<br />{` `}<br /></p>
        <p><strong parentName="p">{`Update`}</strong>{`: Change icon library from Feather Icon to Tabler Icon `}<br />{` `}<br /></p>
        <p><strong parentName="p">{`Update`}</strong>{`: Update base component sidebar, add child menu to show when hover sidebar in collapsed state and update icons `}<br />{` `}<br /></p>
        <p><strong parentName="p">{`Update`}</strong>{`: Covert to new hi-fidelity design pattern login & register in Legion Project - Pattern Library Website `}<br />{` `}<br /></p>
        <p><strong parentName="p">{`New`}</strong>{`: Add Uploader component in Legion Project - UI Library Internal - Website UI Component `}<br />{` `}<br /></p>
        <p><strong parentName="p">{`New`}</strong>{`: Add Pattern Dashboard in Legion Project - Pattern Library Website `}<br />{` `}<br /></p>
      </AccordionItem>
      <AccordionItem title="4 April 2023" mdxType="AccordionItem">
        <h3><strong parentName="h3">{`Website Updates`}</strong>{` `}<br />{` `}<br /></h3>
        <p><strong parentName="p">{`Website Documentation - Release Version : 2.46.25`}</strong>{` `}<br />{` `}<br /></p>
        <p><strong parentName="p">{`Update`}</strong>{`: Component content structure and width `}<br /><br /></p>
        <p><strong parentName="p">{`Update`}</strong>{`: New and More consistent props naming, still supports legacy props whatsoever! (`}<inlineCode parentName="p">{`Accordion`}</inlineCode>{`, `}<inlineCode parentName="p">{`Anchor`}</inlineCode>{`, `}<inlineCode parentName="p">{`Avatar`}</inlineCode>{`, `}<inlineCode parentName="p">{`Badge`}</inlineCode>{`, `}<inlineCode parentName="p">{`Card`}</inlineCode>{`, `}<inlineCode parentName="p">{`Checkbox`}</inlineCode>{`, `}<inlineCode parentName="p">{`Carousel`}</inlineCode>{`, `}<inlineCode parentName="p">{`Switch`}</inlineCode>{`, `}<inlineCode parentName="p">{`Textfield`}</inlineCode>{`) `}<br /><br /></p>
        <p><strong parentName="p">{`Update`}</strong>{`: Add `}<inlineCode parentName="p">{`sx`}</inlineCode>{` support for `}<inlineCode parentName="p">{`Button`}</inlineCode>{` component `}<br /><br /></p>
        <p><strong parentName="p">{`Update`}</strong>{`: Add `}<inlineCode parentName="p">{`color`}</inlineCode>{` props support for `}<inlineCode parentName="p">{`Button`}</inlineCode>{` component `}<br /><br /></p>
        <p><strong parentName="p">{`Update`}</strong>{`: Add new `}<inlineCode parentName="p">{`Datepicker & DateRangePicker`}</inlineCode>{` component`}<br /><br /></p>
        <p><strong parentName="p">{`Update`}</strong>{`: Add new `}<inlineCode parentName="p">{`Stepper`}</inlineCode>{` component `}<br /><br /></p>
        <p><strong parentName="p">{`Update`}</strong>{`: Add new `}<inlineCode parentName="p">{`TabBar`}</inlineCode>{` component `}<br /><br /></p>
        <p><strong parentName="p">{`Update`}</strong>{`: Add new props towards `}<inlineCode parentName="p">{`Sidebar`}</inlineCode>{` component (New active state variant, hidden menu option, and badge addition support) `}<br /><br /></p>
        <p><strong parentName="p">{`Update`}</strong>{`: Select component’s box shadow color to be connected with each theme’s token `}<br />{` `}<br /></p>
        <p><strong parentName="p">{`Update`}</strong>{`: Card component’s props and documentation `}<br />{` `}<br /></p>
        <p><strong parentName="p">{`Update`}</strong>{`: Add margin to the right of `}<inlineCode parentName="p">{`Modal`}</inlineCode>{`’s title instead of giving margin left from `}<inlineCode parentName="p">{`Modal`}</inlineCode>{`’s icon title `}<br />{` `}<br /></p>
        <p><strong parentName="p">{`Update`}</strong>{`: Give default global font family for `}<inlineCode parentName="p">{`Textarea`}</inlineCode>{` component `}<br />{` `}<br /></p>
        <p><strong parentName="p">{`Update`}</strong>{`: Give `}<inlineCode parentName="p">{`DynamicIcon`}</inlineCode>{` component color props to fulfill custom icon coloring `}<br />{` `}<br /></p>
        <p><strong parentName="p">{`Update`}</strong>{`: Flexible font size for `}<inlineCode parentName="p">{`Accordion`}</inlineCode>{` component `}<br />{` `}<br /></p>
        <p><strong parentName="p">{`Update`}</strong>{`: Dynamic background and text color in `}<inlineCode parentName="p">{`Select`}</inlineCode>{` component states `}<br />{` `}<br /></p>
        <p><strong parentName="p">{`Fix`}</strong>{`: Make `}<inlineCode parentName="p">{`onClick`}</inlineCode>{` optinal for `}<inlineCode parentName="p">{`Chip`}</inlineCode>{` component `}<br />{` `}<br /></p>
        <p><strong parentName="p">{`Fix`}</strong>{`: Error children types on `}<inlineCode parentName="p">{`Accordion`}</inlineCode>{`, `}<inlineCode parentName="p">{`Modal`}</inlineCode>{`, `}<inlineCode parentName="p">{`Switch`}</inlineCode>{` component `}<br />{` `}<br /></p>
        <p><strong parentName="p">{`Fix`}</strong>{`: Error key props on `}<inlineCode parentName="p">{`Carousel`}</inlineCode>{` component `}<br />{` `}<br /></p>
        <p><strong parentName="p">{`Fix`}</strong>{`: Error props `}<inlineCode parentName="p">{`sizeBox`}</inlineCode>{` because of rest parameter on `}<inlineCode parentName="p">{`Checkbox`}</inlineCode>{` component `}<br />{` `}<br /></p>
        <p><strong parentName="p">{`Fix`}</strong>{`: `}<inlineCode parentName="p">{`z-index`}</inlineCode>{` priority issue on `}<inlineCode parentName="p">{`Textfield`}</inlineCode>{` component `}<br />{` `}<br /></p>
        <p><strong parentName="p">{`Fix`}</strong>{`: `}<inlineCode parentName="p">{`TabBar`}</inlineCode>{` `}<inlineCode parentName="p">{`Wrapper`}</inlineCode>{` not rendering in Legion Site properly `}<br />{` `}<br /></p>
        <p><strong parentName="p">{`Update`}</strong>{`: New Tab Bar Component `}<br />{` `}<br /></p>
        <p><strong parentName="p">{`Update`}</strong>{`: Add Success State on Password Field `}<br />{` `}<br /></p>
        <p><strong parentName="p">{`Update`}</strong>{`: New Badge Component `}<br />{` `}<br /></p>
        <p><strong parentName="p">{`Update`}</strong>{`: Revamp Single Text Field Prefix and Suffix `}<br />{` `}<br /></p>
        <p><strong parentName="p">{`Update`}</strong>{`: Add Compability Background Color on Single Text Field `}<br />{` `}<br /></p>
        <p><strong parentName="p">{`Update`}</strong>{`: New Alert Component `}<br />{` `}<br /></p>
        <p><strong parentName="p">{`Theme`}</strong>{`: Add `}<inlineCode parentName="p">{`AGR`}</inlineCode>{` theme for `}<inlineCode parentName="p">{`Tab Bar`}</inlineCode>{` `}<br />{` `}<br /></p>
        <p><strong parentName="p">{`Theme`}</strong>{`: Add `}<inlineCode parentName="p">{`AGL`}</inlineCode>{` theme for `}<inlineCode parentName="p">{`Tab Bar`}</inlineCode>{` `}<br />{` `}<br /></p>
        <p><strong parentName="p">{`Theme`}</strong>{`: Add `}<inlineCode parentName="p">{`AQF`}</inlineCode>{` theme for `}<inlineCode parentName="p">{`Tab Bar`}</inlineCode>{` `}<br />{` `}<br /></p>
        <p><strong parentName="p">{`Theme`}</strong>{`: Add `}<inlineCode parentName="p">{`MTN`}</inlineCode>{` theme for `}<inlineCode parentName="p">{`Tab Bar`}</inlineCode>{` `}<br />{` `}<br /></p>
        <p><strong parentName="p">{`Fix`}</strong>{`: Fix broken `}<inlineCode parentName="p">{`Textfield`}</inlineCode>{`’s `}<inlineCode parentName="p">{`LGNContentModel`}</inlineCode>{` inaccessible from all themes `}<br />{` `}<br /></p>
        <p><strong parentName="p">{`Fix`}</strong>{`: Enable swipe-up to expand `}<inlineCode parentName="p">{`LGNSheetBottom`}</inlineCode>{` `}<br />{` `}<br /></p>
        <p><strong parentName="p">{`Fix`}</strong>{`: Open few parameters for modification on `}<inlineCode parentName="p">{`LGNInlineTextField`}</inlineCode>{`, `}<inlineCode parentName="p">{`LGNOutlineTextField`}</inlineCode>{`, `}<inlineCode parentName="p">{`LGNInlineTextView`}</inlineCode>{` and `}<inlineCode parentName="p">{`LGNOutlineTextView`}</inlineCode>{` `}<br />{` `}<br /></p>
        <p><strong parentName="p">{`Fix`}</strong>{`: Fix inaccessible fonts on Themes `}<br />{` `}<br /></p>
        <p><strong parentName="p">{`Fix`}</strong>{`: Adjust theme color token and font accessibility `}<br />{` `}<br /></p>
        <p><strong parentName="p">{`Fix`}</strong>{`: Fix color and typography for `}<inlineCode parentName="p">{`ThemeEazy`}</inlineCode>{` `}<br />{` `}<br /></p>
        <p><strong parentName="p">{`Fix`}</strong>{`: Add namespace to color assets `}<br />{` `}<br /></p>
        <p><strong parentName="p">{`Update`}</strong>{`: Create `}<inlineCode parentName="p">{`ThemeEazy`}</inlineCode>{` `}<br />{` `}<br /></p>
        <h3><strong parentName="h3">{`Development Library Updates`}</strong>{` `}<br />{` `}<br /></h3>
        <p><strong parentName="p">{`Website (Legion-UI) - Release Version : 1.0.44`}</strong>{` `}<br />{` `}<br /></p>
        <p><strong parentName="p">{`Update`}</strong>{`: New and More consistent props naming, still supports legacy props whatsoever! (`}<inlineCode parentName="p">{`Accordion`}</inlineCode>{`, `}<inlineCode parentName="p">{`Anchor`}</inlineCode>{`, `}<inlineCode parentName="p">{`Avatar`}</inlineCode>{`, `}<inlineCode parentName="p">{`Badge`}</inlineCode>{`, `}<inlineCode parentName="p">{`Card`}</inlineCode>{`, `}<inlineCode parentName="p">{`Checkbox`}</inlineCode>{`, `}<inlineCode parentName="p">{`Carousel`}</inlineCode>{`, `}<inlineCode parentName="p">{`Switch`}</inlineCode>{`, `}<inlineCode parentName="p">{`Textfield`}</inlineCode>{`) `}<br />{` `}<br /></p>
        <p><strong parentName="p">{`Update`}</strong>{`: Add `}<inlineCode parentName="p">{`sx`}</inlineCode>{` support for `}<inlineCode parentName="p">{`Button`}</inlineCode>{` component `}<br />{` `}<br /></p>
        <p><strong parentName="p">{`Update`}</strong>{`: Add `}<inlineCode parentName="p">{`color`}</inlineCode>{` props support for `}<inlineCode parentName="p">{`Button`}</inlineCode>{` component `}<br />{` `}<br /></p>
        <p><strong parentName="p">{`Update`}</strong>{`: Add new `}<inlineCode parentName="p">{`Datepicker & DateRangePicker`}</inlineCode>{` component `}<br />{` `}<br /></p>
        <p><strong parentName="p">{`Update`}</strong>{`: Add new `}<inlineCode parentName="p">{`Stepper`}</inlineCode>{` component `}<br />{` `}<br /></p>
        <p><strong parentName="p">{`Update`}</strong>{`: Add new `}<inlineCode parentName="p">{`TabBar`}</inlineCode>{` component `}<br />{` `}<br /></p>
        <p><strong parentName="p">{`Update`}</strong>{`: Add new props towards `}<inlineCode parentName="p">{`Sidebar`}</inlineCode>{` component (New active state variant, hidden menu option, and badge addition support) `}<br />{` `}<br /></p>
        <p><strong parentName="p">{`Update`}</strong>{`: Select component’s box shadow color to be connected with each theme’s token `}<br />{` `}<br /></p>
        <p><strong parentName="p">{`Update`}</strong>{`: Card component’s props and documentation `}<br />{` `}<br /></p>
        <p><strong parentName="p">{`Update`}</strong>{`: Add margin to the right of `}<inlineCode parentName="p">{`Modal`}</inlineCode>{`’s title instead of giving margin left from `}<inlineCode parentName="p">{`Modal`}</inlineCode>{`’s icon title `}<br />{` `}<br /></p>
        <p><strong parentName="p">{`Update`}</strong>{`: Give default global font family for `}<inlineCode parentName="p">{`Textarea`}</inlineCode>{` component `}<br />{` `}<br /></p>
        <p><strong parentName="p">{`Update`}</strong>{`: Give `}<inlineCode parentName="p">{`DynamicIcon`}</inlineCode>{` component color props to fulfill custom icon coloring `}<br />{` `}<br /></p>
        <p><strong parentName="p">{`Update`}</strong>{`: Flexible font size for `}<inlineCode parentName="p">{`Accordion`}</inlineCode>{` component `}<br />{` `}<br /></p>
        <p><strong parentName="p">{`Update`}</strong>{`: Dynamic background and text color in `}<inlineCode parentName="p">{`Select`}</inlineCode>{` component states `}<br />{` `}<br /></p>
        <p><strong parentName="p">{`Fix`}</strong>{`: Make `}<inlineCode parentName="p">{`onClick`}</inlineCode>{` optinal for `}<inlineCode parentName="p">{`Chip`}</inlineCode>{` component `}<br />{` `}<br /></p>
        <p><strong parentName="p">{`Fix`}</strong>{`: Error children types on `}<inlineCode parentName="p">{`Accordion`}</inlineCode>{`, `}<inlineCode parentName="p">{`Modal`}</inlineCode>{`, `}<inlineCode parentName="p">{`Switch`}</inlineCode>{` component `}<br />{` `}<br /></p>
        <p><strong parentName="p">{`Fix`}</strong>{`: Error key props on `}<inlineCode parentName="p">{`Carousel`}</inlineCode>{` component `}<br />{` `}<br /></p>
        <p><strong parentName="p">{`Fix`}</strong>{`: Error props `}<inlineCode parentName="p">{`sizeBox`}</inlineCode>{` because of rest parameter on `}<inlineCode parentName="p">{`Checkbox`}</inlineCode>{` component `}<br />{` `}<br /></p>
        <p><strong parentName="p">{`Fix`}</strong>{`: `}<inlineCode parentName="p">{`z-index`}</inlineCode>{` priority issue on `}<inlineCode parentName="p">{`Textfield`}</inlineCode>{` component `}<br />{` `}<br /></p>
        <p><strong parentName="p">{`Fix`}</strong>{`: `}<inlineCode parentName="p">{`TabBar`}</inlineCode>{` `}<inlineCode parentName="p">{`Wrapper`}</inlineCode>{` not rendering in Legion Site properly `}<br />{` `}<br /></p>
        <p><strong parentName="p">{`Fix`}</strong>{`: `}<inlineCode parentName="p">{`Button`}</inlineCode>{` component’s to only use its original dimension `}<br />{` `}<br /></p>
        <h3><strong parentName="h3">{`Design Library Updates`}</strong>{` `}<br /><br /></h3>
        <p><strong parentName="p">{`Website Pattern and UI Kit - Release Version : 1.7.12`}</strong>{` `}<br />{` `}<br /></p>
        <p><strong parentName="p">{`Update`}</strong>{` : Add tab properties and icon option inside menu for Mega Menu component `}<br />{` `}<br /></p>
        <p><strong parentName="p">{`Update`}</strong>{` : Change line orientation to inside for Textfield component `}<br />{` `}<br /></p>
        <p><strong parentName="p">{`Update`}</strong>{` : Add size option properties in master Date Range Picker component `}<br />{` `}<br /></p>
        <p><strong parentName="p">{`Update`}</strong>{` : Add badge option, collapse, expanded, footer content, and style variants in Sidebar component `}<br />{` `}<br /></p>
        <p><strong parentName="p">{`Update`}</strong>{` : Add color swap option in buttons components `}<br />{` `}<br /></p>
        <p><strong parentName="p">{`Update`}</strong>{` : Add own base list in dropdown component to solve unlink from base component `}<br />{` `}<br /></p>
        <p><strong parentName="p">{`Update`}</strong>{` : Add state hover, selected and icon option in chips components `}<br />{` `}<br /></p>
        <p><strong parentName="p">{`Update`}</strong>{` : variant of divider component matching with code assets `}<br />{` `}<br /></p>
      </AccordionItem>
      <AccordionItem title="7 February 2023" mdxType="AccordionItem">
        <h3><strong parentName="h3">{`Website Updates`}</strong>{` `}<br />{` `}<br /></h3>
        <p><strong parentName="p">{`Website Documentation - Release Version : 2.44.12`}</strong>{` `}<br />{` `}<br /></p>
        <p><strong parentName="p">{`Update`}</strong>{`: Add new `}<inlineCode parentName="p">{`Alert`}</inlineCode>{` component for `}<inlineCode parentName="p">{`Website`}</inlineCode>{` and `}<inlineCode parentName="p">{`iOS`}</inlineCode>{` `}<br />{` `}<br />{`
`}<strong parentName="p">{`Update`}</strong>{`: Add new `}<inlineCode parentName="p">{`Carousel`}</inlineCode>{` component for `}<inlineCode parentName="p">{`Website`}</inlineCode>{` `}<br />{` `}<br />{`
`}<strong parentName="p">{`Update`}</strong>{`: Add new `}<inlineCode parentName="p">{`Megamenu`}</inlineCode>{` component `}<inlineCode parentName="p">{`Website`}</inlineCode>{` `}<br />{` `}<br />{`
`}<strong parentName="p">{`Update`}</strong>{`: Add async feature for `}<inlineCode parentName="p">{`Select`}</inlineCode>{` component for `}<inlineCode parentName="p">{`Website`}</inlineCode>{` `}<br />{` `}<br />{`
`}<strong parentName="p">{`Update`}</strong>{`: Adjust Z-Index level on `}<inlineCode parentName="p">{`Dropdown`}</inlineCode>{` component for `}<inlineCode parentName="p">{`Website`}</inlineCode>{` `}<br />{` `}<br />{`
`}<strong parentName="p">{`Update`}</strong>{`: Adjust `}<inlineCode parentName="p">{`DataTable`}</inlineCode>{` component styling for `}<inlineCode parentName="p">{`Website`}</inlineCode>{` `}<br />{` `}<br />{`
`}<strong parentName="p">{`Update`}</strong>{`: Spinner Component for `}<inlineCode parentName="p">{`Android`}</inlineCode>{` `}<br />{` `}<br />{`
`}<strong parentName="p">{`Update`}</strong>{`: Add compatibility to change tint navigation icon on `}<inlineCode parentName="p">{`Toolbar`}</inlineCode>{` for `}<inlineCode parentName="p">{`Android`}</inlineCode>{` `}<br />{` `}<br />{`
`}<strong parentName="p">{`Update`}</strong>{`: Added component `}<inlineCode parentName="p">{`Bottom Sheet Dialog`}</inlineCode>{` for `}<inlineCode parentName="p">{`Android`}</inlineCode>{` `}<br />{` `}<br />{`
`}<strong parentName="p">{`Update`}</strong>{`: Added component `}<inlineCode parentName="p">{`Image View`}</inlineCode>{` for `}<inlineCode parentName="p">{`Android`}</inlineCode>{` `}<br />{` `}<br />{`
`}<strong parentName="p">{`Update`}</strong>{`: Added capability `}<inlineCode parentName="p">{`Button`}</inlineCode>{` to set transparent variant for `}<inlineCode parentName="p">{`Android`}</inlineCode>{` `}<br />{` `}<br />{`
`}<strong parentName="p">{`New Theme`}</strong>{`: Added new theme support `}<inlineCode parentName="p">{`Agree Fisheries`}</inlineCode>{` for `}<inlineCode parentName="p">{`Android`}</inlineCode>{` and `}<inlineCode parentName="p">{`iOS`}</inlineCode>{` `}<br />{` `}<br />{`
`}<strong parentName="p">{`New Theme`}</strong>{`: Added new theme support `}<inlineCode parentName="p">{`Agree Livestock`}</inlineCode>{` for `}<inlineCode parentName="p">{`Android`}</inlineCode>{` and `}<inlineCode parentName="p">{`iOS`}</inlineCode>{` `}<br />{` `}<br />{`
`}<strong parentName="p">{`New Theme`}</strong>{`: Added new theme support `}<inlineCode parentName="p">{`MyTens`}</inlineCode>{` for `}<inlineCode parentName="p">{`Android`}</inlineCode>{` and `}<inlineCode parentName="p">{`iOS`}</inlineCode>{` `}<br />{` `}<br />{`
`}<strong parentName="p">{`Bug Fix`}</strong>{`: Divider Colors for `}<inlineCode parentName="p">{`Android`}</inlineCode>{` `}<br />{` `}<br />{`
`}<strong parentName="p">{`Bug Fix`}</strong>{`: Icon Button not tint correctly for `}<inlineCode parentName="p">{`Android`}</inlineCode>{` `}<br />{` `}<br />{`
`}<strong parentName="p">{`Bug Fix`}</strong>{`: `}<inlineCode parentName="p">{`isEnabled`}</inlineCode>{` Status Wrong on Outline type Button for `}<inlineCode parentName="p">{`Android`}</inlineCode>{` `}<br />{` `}<br />{`
`}<strong parentName="p">{`Bug Fix`}</strong>{`: `}<inlineCode parentName="p">{`isEnabled`}</inlineCode>{` Status Wrong on Transparent type Button for `}<inlineCode parentName="p">{`Android`}</inlineCode>{` `}<br />{` `}<br />{`
`}<strong parentName="p">{`Bug Fix`}</strong>{`: Limit Photo Not Work on `}<inlineCode parentName="p">{`MultiplePhotoField`}</inlineCode>{` for `}<inlineCode parentName="p">{`Android`}</inlineCode>{` `}<br />{` `}<br />{`
`}<strong parentName="p">{`Bug Fix`}</strong>{`: Anchor Text Padding for `}<inlineCode parentName="p">{`Android`}</inlineCode>{` `}<br />{` `}<br />{`
`}<strong parentName="p">{`Bug Fix`}</strong>{`: `}<inlineCode parentName="p">{`Bottom Sheet`}</inlineCode>{` not dismissed after pick photo from camera for `}<inlineCode parentName="p">{`Android`}</inlineCode>{` `}<br /></p>
        <h3><strong parentName="h3">{`Development Library Updates`}</strong>{` `}<br />{` `}<br /></h3>
        <p><strong parentName="p">{`Website (Legion-UI) - Release Version : 1.0.40`}</strong>{` `}<br /></p>
        <p><strong parentName="p">{`Update`}</strong>{`: Add new `}<inlineCode parentName="p">{`Alert`}</inlineCode>{` component `}<br /></p>
        <p><strong parentName="p">{`Update`}</strong>{`: Add new `}<inlineCode parentName="p">{`Carousel`}</inlineCode>{` component `}<br /></p>
        <p><strong parentName="p">{`Update`}</strong>{`: Add new `}<inlineCode parentName="p">{`Chip`}</inlineCode>{` component `}<br /></p>
        <p><strong parentName="p">{`Update`}</strong>{`: Add new `}<inlineCode parentName="p">{`Megamenu`}</inlineCode>{` component `}<br /></p>
        <p><strong parentName="p">{`Update`}</strong>{`: Add new `}<inlineCode parentName="p">{`Modal`}</inlineCode>{` component `}<br /></p>
        <p><strong parentName="p">{`Update`}</strong>{`: Add async feature for `}<inlineCode parentName="p">{`Select`}</inlineCode>{` component `}<br /></p>
        <p><strong parentName="p">{`Update`}</strong>{`: Adjust Z-Index level on `}<inlineCode parentName="p">{`Dropdown`}</inlineCode>{` component `}<br /></p>
        <p><strong parentName="p">{`Update`}</strong>{`: Adjust `}<inlineCode parentName="p">{`DataTable`}</inlineCode>{` component styling `}<br /></p>
        <h3><strong parentName="h3">{`Design Library Updates`}</strong>{` `}<br />{` `}<br /></h3>
        <p><strong parentName="p">{`Website Pattern and UI Kit - Release Version : 1.7.4`}</strong>{` `}<br />{` `}<br /></p>
        <p><strong parentName="p">{`New`}</strong>{` : Add Alert component in Legion Project - UI Library - Website UI Component `}<br />{` `}<br /></p>
        <p><strong parentName="p">{`New`}</strong>{` : Add Mega Menu component in Legion Project - UI Library - Website UI Component `}<br />{` `}<br /></p>
        <p><strong parentName="p">{`New`}</strong>{` : Add Stepper component in Legion Project - UI Library - Website UI Component `}<br />{` `}<br /></p>
        <p><strong parentName="p">{`Update`}</strong>{` : Add Variant type Sub Menu & Group menu Sidebar component in Legion Project - UI Library - Website UI Component `}<br />{` `}<br /></p>
        <p><strong parentName="p">{`Update`}</strong>{` : Add Variant size Medium & Small Date Range component in Legion Project - UI Library - Website UI Component `}<br />{` `}<br /></p>
        <p><strong parentName="p">{`Update`}</strong>{` : Default colors Tooltips component in Legion Project - UI Library - Website UI Component `}<br />{` `}<br /></p>
        <p><strong parentName="p">{`Update`}</strong>{` : Add standalone base list Dropdown component in Legion Project - UI Library - Website UI Component `}<br />{` `}<br /></p>
        <p><strong parentName="p">{`Update`}</strong>{` : Add development status information & design guideline link update in Legion Project - UI Library - Website UI Component `}<br />{` `}<br /></p>
      </AccordionItem>
      <AccordionItem title="15 December 2022" mdxType="AccordionItem">
        <h3><strong parentName="h3">{`Website Updates`}</strong>{` `}<br />{` `}<br /></h3>
        <p><strong parentName="p">{`Website Documentation - Release Version : 2.30.2`}</strong>{` `}<br />{` `}<br /></p>
        <p><strong parentName="p">{`Update`}</strong>{` : Add new `}<inlineCode parentName="p">{`Accordion`}</inlineCode>{` Component for `}<inlineCode parentName="p">{`Website`}</inlineCode>{` & `}<inlineCode parentName="p">{`Guideline`}</inlineCode>{` `}<br />{` `}<br /></p>
        <p><strong parentName="p">{`Update`}</strong>{` : Add new `}<inlineCode parentName="p">{`Avatar`}</inlineCode>{` Component for `}<inlineCode parentName="p">{`IOS`}</inlineCode>{` `}<br />{` `}<br /></p>
        <p><strong parentName="p">{`Update`}</strong>{` : Add new `}<inlineCode parentName="p">{`Box`}</inlineCode>{` Component for `}<inlineCode parentName="p">{`Website`}</inlineCode>{` `}<br />{` `}<br /></p>
        <p><strong parentName="p">{`Update`}</strong>{` : Add new `}<inlineCode parentName="p">{`Button`}</inlineCode>{` Component for `}<inlineCode parentName="p">{`IOS`}</inlineCode>{` `}<br />{` `}<br /></p>
        <p><strong parentName="p">{`Update`}</strong>{` : Add new `}<inlineCode parentName="p">{`Chip`}</inlineCode>{` Component for `}<inlineCode parentName="p">{`Android`}</inlineCode>{` & `}<inlineCode parentName="p">{`Guideline`}</inlineCode>{` `}<br />{` `}<br /></p>
        <p><strong parentName="p">{`Update`}</strong>{` : Add new `}<inlineCode parentName="p">{`Dropdown`}</inlineCode>{` Component for `}<inlineCode parentName="p">{`Website`}</inlineCode>{` & `}<inlineCode parentName="p">{`Guideline`}</inlineCode>{` `}<br />{` `}<br /></p>
        <p><strong parentName="p">{`Update`}</strong>{` : Add new `}<inlineCode parentName="p">{`Flex`}</inlineCode>{` Component for `}<inlineCode parentName="p">{`Website`}</inlineCode>{` `}<br />{` `}<br /></p>
        <p><strong parentName="p">{`Update`}</strong>{` : Add new `}<inlineCode parentName="p">{`Grid`}</inlineCode>{` Component for `}<inlineCode parentName="p">{`Website`}</inlineCode>{` `}<br />{` `}<br /></p>
        <p><strong parentName="p">{`Update`}</strong>{` : Add new `}<inlineCode parentName="p">{`Image`}</inlineCode>{` Component for `}<inlineCode parentName="p">{`Website`}</inlineCode>{` & `}<inlineCode parentName="p">{`Guideline`}</inlineCode>{` `}<br />{` `}<br /></p>
        <p><strong parentName="p">{`Update`}</strong>{` : Add new `}<inlineCode parentName="p">{`Navigation Bar`}</inlineCode>{` Component for `}<inlineCode parentName="p">{`Website`}</inlineCode>{` & `}<inlineCode parentName="p">{`Guideline`}</inlineCode>{` `}<br />{` `}<br /></p>
        <p><strong parentName="p">{`Update`}</strong>{` : Add new `}<inlineCode parentName="p">{`Radio Button`}</inlineCode>{` Component for `}<inlineCode parentName="p">{`IOS`}</inlineCode>{`, `}<inlineCode parentName="p">{`Android`}</inlineCode>{` & `}<inlineCode parentName="p">{`Guideline`}</inlineCode>{` `}<br />{` `}<br /></p>
        <p><strong parentName="p">{`Update`}</strong>{` : Add new `}<inlineCode parentName="p">{`sidebar`}</inlineCode>{` Component for `}<inlineCode parentName="p">{`Website`}</inlineCode>{` & `}<inlineCode parentName="p">{`Guideline`}</inlineCode>{` `}<br />{` `}<br /></p>
        <p><strong parentName="p">{`Update`}</strong>{` : Add new `}<inlineCode parentName="p">{`Switch`}</inlineCode>{` Component for `}<inlineCode parentName="p">{`IOS`}</inlineCode>{` & `}<inlineCode parentName="p">{`Android`}</inlineCode>{` `}<br />{` `}<br /></p>
        <p><strong parentName="p">{`Update`}</strong>{` : Add new `}<inlineCode parentName="p">{`TextField`}</inlineCode>{` Component for `}<inlineCode parentName="p">{`IOS`}</inlineCode>{` `}<br />{` `}<br /></p>
        <p><strong parentName="p">{`Update`}</strong>{` : Add new `}<inlineCode parentName="p">{`TextArea`}</inlineCode>{` Component for `}<inlineCode parentName="p">{`IOS`}</inlineCode>{` `}<br />{` `}<br /></p>
        <p><strong parentName="p">{`Update`}</strong>{` : Add new `}<inlineCode parentName="p">{`View State`}</inlineCode>{` Component for `}<inlineCode parentName="p">{`Android`}</inlineCode>{` `}<br /></p>
        <h3><strong parentName="h3">{`Development Library Updates`}</strong>{` `}<br />{` `}<br /></h3>
        <p><strong parentName="p">{`Website (Legion-UI) - Release Version : 1.0.19`}</strong>{` `}<br />{` `}<br /></p>
        <p><strong parentName="p">{`Update`}</strong>{` : Add new `}<inlineCode parentName="p">{`Accordion`}</inlineCode>{` Component `}<br />{` `}<br /></p>
        <p><strong parentName="p">{`Update`}</strong>{` : Add new `}<inlineCode parentName="p">{`Box`}</inlineCode>{` Component`}<br />{` `}<br /></p>
        <p><strong parentName="p">{`Update`}</strong>{` : Add new `}<inlineCode parentName="p">{`Data Table`}</inlineCode>{` Component`}<br />{` `}<br /></p>
        <p><strong parentName="p">{`Update`}</strong>{` : Add new `}<inlineCode parentName="p">{`Dropdown`}</inlineCode>{` Component `}<br />{` `}<br /></p>
        <p><strong parentName="p">{`Update`}</strong>{` : Add new `}<inlineCode parentName="p">{`Flex`}</inlineCode>{` Component `}<br />{` `}<br /></p>
        <p><strong parentName="p">{`Update`}</strong>{` : Add new `}<inlineCode parentName="p">{`Grid`}</inlineCode>{` Component `}<br />{` `}<br /></p>
        <p><strong parentName="p">{`Update`}</strong>{` : Add new `}<inlineCode parentName="p">{`Image`}</inlineCode>{` Component `}<br />{` `}<br /></p>
        <p><strong parentName="p">{`Update`}</strong>{` : Add new `}<inlineCode parentName="p">{`Navigation Bar`}</inlineCode>{` Component `}<br />{` `}<br /></p>
        <p><strong parentName="p">{`Update`}</strong>{` : Add new `}<inlineCode parentName="p">{`sidebar`}</inlineCode>{` Component `}<br /></p>
        <h3><strong parentName="h3">{`Design Library Updates`}</strong>{` `}<br />{` `}<br /></h3>
        <p><strong parentName="p">{`Website Pattern and UI Kit - Release Version : 1.7.0`}</strong>{` `}<br />{` `}<br /></p>
        <p><strong parentName="p">{`Update`}</strong>{` : Add Date Picker component in Legion Project - UI Library - Website UI Component `}<br />{` `}<br /></p>
        <p><strong parentName="p">{`Update`}</strong>{` : Add Range Picker component in Legion Project - UI Library - Website UI Component `}<br />{` `}<br /></p>
        <p><strong parentName="p">{`Update`}</strong>{` : Add Carousel component in Legion Project - UI Library - Website UI Component `}<br />{` `}<br /></p>
        <p><strong parentName="p">{`Update`}</strong>{` : Add Dropdown component in Legion Project - UI Library - Website UI Component `}<br />{` `}<br /></p>
        <p><strong parentName="p">{`Update`}</strong>{` : Change variant Chips component in Legion Project - UI Library - Website UI Component `}<br />{` `}<br /></p>
      </AccordionItem>
      <AccordionItem title="3 November 2022" mdxType="AccordionItem">
        <h3><strong parentName="h3">{`Website Updates`}</strong>{` `}<br />{` `}<br /></h3>
        <p><strong parentName="p">{`Website Documentation - Release Version : 2.17.2`}</strong>{` `}<br />{` `}<br /></p>
        <p><strong parentName="p">{`Update`}</strong>{` : Add `}<inlineCode parentName="p">{`Top of Content`}</inlineCode>{` feature `}<br />{` `}<br /></p>
        <p><strong parentName="p">{`Update`}</strong>{` : Update field `}<inlineCode parentName="p">{`Multitheme select`}</inlineCode>{` `}<br />{` `}<br /></p>
        <p><strong parentName="p">{`Update`}</strong>{` : Add `}<inlineCode parentName="p">{`Icon`}</inlineCode>{` in sidebar navigation `}<br />{` `}<br /></p>
        <p><strong parentName="p">{`Update`}</strong>{` : Add Badge for `}<inlineCode parentName="p">{`New`}</inlineCode>{`, `}<inlineCode parentName="p">{`Update`}</inlineCode>{` & `}<inlineCode parentName="p">{`Upcoming`}</inlineCode>{` Component `}<br />{` `}<br /></p>
        <p><strong parentName="p">{`Update`}</strong>{` : Add Instalation & Configuration for IOS Platform `}<br />{` `}<br /></p>
        <p><strong parentName="p">{`Update`}</strong>{` : Add new `}<inlineCode parentName="p">{`Card`}</inlineCode>{` Component for `}<inlineCode parentName="p">{`Website`}</inlineCode>{` & `}<inlineCode parentName="p">{`Guideline`}</inlineCode>{` `}<br />{` `}<br /></p>
        <p><strong parentName="p">{`Update`}</strong>{` : Add new `}<inlineCode parentName="p">{`Snackbar`}</inlineCode>{` Component for `}<inlineCode parentName="p">{`Android`}</inlineCode>{` & `}<inlineCode parentName="p">{`Guideline`}</inlineCode>{` `}<br />{` `}<br /></p>
        <p><strong parentName="p">{`Update`}</strong>{` : Add new `}<inlineCode parentName="p">{`Table`}</inlineCode>{` Component for `}<inlineCode parentName="p">{`Website`}</inlineCode>{` & `}<inlineCode parentName="p">{`Guideline`}</inlineCode>{` `}<br />{` `}<br /></p>
        <p><strong parentName="p">{`Update`}</strong>{` : Add new `}<inlineCode parentName="p">{`Tooltip`}</inlineCode>{` Component for `}<inlineCode parentName="p">{`Website`}</inlineCode>{` & `}<inlineCode parentName="p">{`Guideline`}</inlineCode>{` `}<br />{` `}<br /></p>
        <p><strong parentName="p">{`Update`}</strong>{` : Add section Error Message in `}<inlineCode parentName="p">{`Textarea`}</inlineCode>{` Component for `}<inlineCode parentName="p">{`Website`}</inlineCode>{` `}<br />{` `}<br /></p>
        <p><strong parentName="p">{`Update`}</strong>{` : Add section Caption in `}<inlineCode parentName="p">{`Textarea`}</inlineCode>{` Component for `}<inlineCode parentName="p">{`Website`}</inlineCode>{` `}<br />{` `}<br /></p>
        <p><strong parentName="p">{`Update`}</strong>{` : Add section Error Message in `}<inlineCode parentName="p">{`TextField`}</inlineCode>{` Component for `}<inlineCode parentName="p">{`Website`}</inlineCode>{` `}<br />{` `}<br /></p>
        <p><strong parentName="p">{`Update`}</strong>{` : Add section Caption in `}<inlineCode parentName="p">{`TextField`}</inlineCode>{` Component for `}<inlineCode parentName="p">{`Website`}</inlineCode>{` `}<br />{` `}<br /></p>
        <p><strong parentName="p">{`Update`}</strong>{` : Add section Size in `}<inlineCode parentName="p">{`TextField`}</inlineCode>{` Component for `}<inlineCode parentName="p">{`Website`}</inlineCode>{` `}<br />{` `}<br /></p>
        <p><strong parentName="p">{`Update`}</strong>{` : sticky position multi Brand selection for all content `}<br />{` `}<br /></p>
        <p><strong parentName="p">{`Update`}</strong>{` : typo title heading of elevation page `}<br />{` `}<br /></p>
        <h3><strong parentName="h3">{`Development Library Updates`}</strong>{` `}<br />{` `}<br /></h3>
        <p><strong parentName="p">{`Website (Legion-UI) - Release Version : 1.9.0`}</strong>{` `}<br />{` `}<br /></p>
        <p><strong parentName="p">{`Update`}</strong>{` : Add new `}<inlineCode parentName="p">{`Card`}</inlineCode>{` Component`}<br />{` `}<br /></p>
        <p><strong parentName="p">{`Update`}</strong>{` : Add new `}<inlineCode parentName="p">{`Select`}</inlineCode>{` Component`}<br />{` `}<br /></p>
        <p><strong parentName="p">{`Update`}</strong>{` : Add new `}<inlineCode parentName="p">{`Table`}</inlineCode>{` Component`}<br />{` `}<br /></p>
        <p><strong parentName="p">{`Update`}</strong>{` : Add new `}<inlineCode parentName="p">{`Tooltips`}</inlineCode>{` Component`}<br />{` `}<br /></p>
        <p><strong parentName="p">{`Update`}</strong>{` : Add properties `}<inlineCode parentName="p">{`Caption`}</inlineCode>{` in `}<inlineCode parentName="p">{`Texfield`}</inlineCode>{` Component for `}<inlineCode parentName="p">{`Website`}</inlineCode><br />{` `}<br /></p>
        <p><strong parentName="p">{`Update`}</strong>{` : Add properties `}<inlineCode parentName="p">{`Caption`}</inlineCode>{` in `}<inlineCode parentName="p">{`Textarea`}</inlineCode>{` Component for `}<inlineCode parentName="p">{`Website`}</inlineCode><br />{` `}<br /></p>
        <p><strong parentName="p">{`Update`}</strong>{` : Add properties `}<inlineCode parentName="p">{`Error Message`}</inlineCode>{` in `}<inlineCode parentName="p">{`Texfield`}</inlineCode>{` Component for `}<inlineCode parentName="p">{`Website`}</inlineCode><br />{` `}<br /></p>
        <p><strong parentName="p">{`Update`}</strong>{` : Add properties `}<inlineCode parentName="p">{`Error Message`}</inlineCode>{` in `}<inlineCode parentName="p">{`Textarea`}</inlineCode>{` Component for `}<inlineCode parentName="p">{`Website`}</inlineCode><br />{` `}<br /></p>
        <p><strong parentName="p">{`Update`}</strong>{` : Add properties `}<inlineCode parentName="p">{`Size`}</inlineCode>{` in `}<inlineCode parentName="p">{`Textfield`}</inlineCode>{` Component for `}<inlineCode parentName="p">{`Website`}</inlineCode><br />{` `}<br /></p>
        <h3><strong parentName="h3">{`Design Library Updates`}</strong>{` `}<br />{` `}<br /></h3>
        <p><strong parentName="p">{`Website Pattern and UI Kit - Release Version : 1.6.0`}</strong>{` `}<br />{` `}<br />{`
`}<strong parentName="p">{`Update`}</strong>{` : Add `}<inlineCode parentName="p">{`Card`}</inlineCode>{` component in Legion Project - UI Library - Website UI Component `}<br />{` `}<br />{`
`}<strong parentName="p">{`Update`}</strong>{` : Add `}<inlineCode parentName="p">{`Select`}</inlineCode>{` component in Legion Project - UI Library - Website UI Component `}<br />{` `}<br />{`
`}<strong parentName="p">{`Update`}</strong>{` : Add `}<inlineCode parentName="p">{`Navigation Bar`}</inlineCode>{` component in Legion Project - UI Library - Website UI Component `}<br />{` `}<br />{`
`}<strong parentName="p">{`Update`}</strong>{` : Add `}<inlineCode parentName="p">{`Date Picker`}</inlineCode>{` component in Legion Project - UI Library - Website UI Component `}<br />{` `}<br />{`
`}<strong parentName="p">{`Update`}</strong>{` : Revamp `}<inlineCode parentName="p">{`Tab Bar`}</inlineCode>{` component with properties and nested component in Legion Project - UI Library - Website UI Component `}<br />{` `}<br />{`
`}<strong parentName="p">{`Update`}</strong>{` : Revamp `}<inlineCode parentName="p">{`Textfield`}</inlineCode>{` component with properties and nested component in Legion Project - UI Library - Website UI Component `}<br />{` `}<br /></p>
      </AccordionItem>
      <AccordionItem title="12 October 2022" mdxType="AccordionItem">
        <h3><strong parentName="h3">{`Website Updates`}</strong>{` `}<br />{` `}<br /></h3>
        <p><strong parentName="p">{`Website Documentation - Release Version : 2.3.1`}</strong>{` `}<br />{` `}<br /></p>
        <p><strong parentName="p">{`Update`}</strong>{` : add documentation `}<inlineCode parentName="p">{`textarea`}</inlineCode>{` component platform `}<inlineCode parentName="p">{`website`}</inlineCode>{` & `}<inlineCode parentName="p">{`android`}</inlineCode>{` include the `}<inlineCode parentName="p">{`guideline`}</inlineCode>{` `}<br />{` `}<br /></p>
        <p><strong parentName="p">{`Update`}</strong>{` : add documentation `}<inlineCode parentName="p">{`textfield`}</inlineCode>{` component platform `}<inlineCode parentName="p">{`website`}</inlineCode>{` & `}<inlineCode parentName="p">{`android`}</inlineCode>{` include the `}<inlineCode parentName="p">{`guideline`}</inlineCode>{` `}<br />{` `}<br /></p>
        <p><strong parentName="p">{`Update`}</strong>{` : add documentation switch component platform `}<inlineCode parentName="p">{`website`}</inlineCode>{` & `}<inlineCode parentName="p">{`android`}</inlineCode>{` include the `}<inlineCode parentName="p">{`guideline`}</inlineCode>{` `}<br />{` `}<br /></p>
        <p><strong parentName="p">{`Update`}</strong>{` : add documentation spinner component platform `}<inlineCode parentName="p">{`website`}</inlineCode>{` & `}<inlineCode parentName="p">{`android`}</inlineCode>{` include the `}<inlineCode parentName="p">{`guideline`}</inlineCode>{` `}<br />{` `}<br /></p>
        <p><strong parentName="p">{`Update`}</strong>{` : add documentation avatar component platform `}<inlineCode parentName="p">{`website`}</inlineCode>{` & `}<inlineCode parentName="p">{`android`}</inlineCode>{` include the `}<inlineCode parentName="p">{`guideline`}</inlineCode>{` `}<br />{` `}<br /></p>
        <p><strong parentName="p">{`Update`}</strong>{` : add documentation badge component platform `}<inlineCode parentName="p">{`website`}</inlineCode>{` & `}<inlineCode parentName="p">{`android`}</inlineCode>{` include the `}<inlineCode parentName="p">{`guideline`}</inlineCode>{` `}<br />{` `}<br /></p>
        <p><strong parentName="p">{`Update`}</strong>{` : props information in `}<inlineCode parentName="p">{`anchor text`}</inlineCode>{`, `}<inlineCode parentName="p">{`button`}</inlineCode>{`, `}<inlineCode parentName="p">{`checkbox`}</inlineCode>{` component for `}<inlineCode parentName="p">{`website`}</inlineCode>{` platform `}<br />{` `}<br /></p>
        <p><strong parentName="p">{`Update`}</strong>{` : add `}<inlineCode parentName="p">{`responsive`}</inlineCode>{` props in `}<inlineCode parentName="p">{`typography`}</inlineCode>{` component `}<br />{` `}<br /></p>
        <p><strong parentName="p">{`Bug Fix`}</strong>{` : `}<inlineCode parentName="p">{`responsive`}</inlineCode>{` layout and content in viewport large screen`}</p>
        <br />
        <h3><strong parentName="h3">{`Development Library Updates`}</strong>{` `}<br />{` `}<br /></h3>
        <p><strong parentName="p">{`Website (Legion-UI) - Release Version : 1.0.11`}</strong>{` `}<br />{` `}<br />{`
`}<strong parentName="p">{`Update`}</strong>{` : add `}<inlineCode parentName="p">{`responsive`}</inlineCode>{` props option for `}<inlineCode parentName="p">{`Heading`}</inlineCode>{`, `}<inlineCode parentName="p">{`Body`}</inlineCode>{`, `}<inlineCode parentName="p">{`Caption`}</inlineCode>{` component `}<br />{` `}<br />{`
`}<strong parentName="p">{`Update`}</strong>{` : add `}<inlineCode parentName="p">{`color`}</inlineCode>{` props option for `}<inlineCode parentName="p">{`Heading`}</inlineCode>{`, `}<inlineCode parentName="p">{`Body`}</inlineCode>{`, `}<inlineCode parentName="p">{`Caption`}</inlineCode>{` component `}<br />{` `}<br />{`
`}<strong parentName="p">{`Update`}</strong>{` : add background and text `}<inlineCode parentName="p">{`color styling`}</inlineCode>{` in all theme `}<br />{` `}<br />{`
`}<strong parentName="p">{`Update`}</strong>{` : replace `}<inlineCode parentName="p">{`textfield`}</inlineCode>{` built in colors with token colors properties `}<br />{` `}<br />{`
`}<strong parentName="p">{`Update`}</strong>{` : add `}<inlineCode parentName="p">{`avatar`}</inlineCode>{` and `}<inlineCode parentName="p">{`badge`}</inlineCode>{` component token value properties `}<br />{` `}<br />{`
`}<strong parentName="p">{`Bug Fix`}</strong>{` : rename `}<inlineCode parentName="p">{`disabledAncor`}</inlineCode>{` props to `}<inlineCode parentName="p">{`disabled`}</inlineCode>{` in `}<inlineCode parentName="p">{`Anchor Text`}</inlineCode>{` component `}<br />{` `}<br />{`
`}<strong parentName="p">{`Bug Fix`}</strong>{` : refactor `}<inlineCode parentName="p">{`Heading`}</inlineCode>{` component, more compact code, serve its heading level `}<br />{` `}<br />{`
`}<strong parentName="p">{`Bug Fix`}</strong>{` : update `}<inlineCode parentName="p">{`switch`}</inlineCode>{` component to used default outline value style `}<br />{` `}<br />{`
`}<strong parentName="p">{`Bug Fix`}</strong>{` : update `}<inlineCode parentName="p">{`textfield`}</inlineCode>{` outline style focussed stated not show the style`}</p>
        <h3><strong parentName="h3">{`Design Library Updates`}</strong>{` `}<br />{` `}<br /></h3>
        <p><strong parentName="p">{`Website Pattern and UI Kit - Release Version : 1.0.8`}</strong>{` `}<br />{` `}<br />{`
`}<strong parentName="p">{`Update`}</strong>{` : Add `}<inlineCode parentName="p">{`changelog information`}</inlineCode>{` and update heading component documentation `}<br />{` `}<br />{`
`}<strong parentName="p">{`Update`}</strong>{` : Change all component color properties with new foundation `}<inlineCode parentName="p">{`color scale`}</inlineCode>{` `}<br />{` `}<br />{`
`}<strong parentName="p">{`Update`}</strong>{` : Add `}<inlineCode parentName="p">{`tabel`}</inlineCode>{` component in Legion Project - UI Library - Website UI Component `}<br />{` `}<br />{`
`}<strong parentName="p">{`Update`}</strong>{` : Add `}<inlineCode parentName="p">{`sidebar`}</inlineCode>{` component in Legion Project - UI Library - Website UI Component `}<br />{` `}<br />{`
`}<strong parentName="p">{`Update`}</strong>{` : Add `}<inlineCode parentName="p">{`spinner`}</inlineCode>{` component in Legion Project - UI Library - Website UI Component `}<br />{` `}<br />{`
`}<strong parentName="p">{`Bug Fix`}</strong>{` : Remove content button, button group, button icon variant type and replace with `}<inlineCode parentName="p">{`slot`}</inlineCode>{` in `}<inlineCode parentName="p">{`divider`}</inlineCode>{` component `}<br />{` `}<br />{`
`}<strong parentName="p">{`Update`}</strong>{` : Add `}<inlineCode parentName="p">{`line`}</inlineCode>{` variant type in `}<inlineCode parentName="p">{`divider`}</inlineCode>{` component `}<br />{` `}<br />{`
`}<strong parentName="p">{`Bug Fix`}</strong>{` : Replace anchor text with `}<inlineCode parentName="p">{`soft button`}</inlineCode>{` in `}<inlineCode parentName="p">{`snackbar`}</inlineCode>{` content base component `}<br />{` `}<br />{`
`}<strong parentName="p">{`Bug Fix`}</strong>{` : Hide avatar option in base component of `}<inlineCode parentName="p">{`badge`}</inlineCode>{` `}<br />{` `}<br />{`
`}<strong parentName="p">{`Update`}</strong>{` : Add variant size `}<inlineCode parentName="p">{`large`}</inlineCode>{` and add new property variant `}<inlineCode parentName="p">{`style`}</inlineCode>{` in `}<inlineCode parentName="p">{`badge`}</inlineCode>{` component `}<br />{` `}<br />{`
`}<strong parentName="p">{`Bug Fix`}</strong>{` : Remove line in state hover and pressed then replace with `}<inlineCode parentName="p">{`undertline text style`}</inlineCode>{` in `}<inlineCode parentName="p">{`anchor text`}</inlineCode>{` component `}<br />{` `}<br />{`
`}<strong parentName="p">{`Update`}</strong>{` : Change variant style primary to `}<inlineCode parentName="p">{`solid`}</inlineCode>{` and secondary to `}<inlineCode parentName="p">{`soft`}</inlineCode>{` in `}<inlineCode parentName="p">{`button`}</inlineCode>{` component `}<br />{` `}<br />{`
`}<strong parentName="p">{`Update`}</strong>{` : Add new variant style `}<inlineCode parentName="p">{`outline`}</inlineCode>{` and `}<inlineCode parentName="p">{`transparent`}</inlineCode>{` in base and include the variant of `}<inlineCode parentName="p">{`button`}</inlineCode>{` component`}</p>
      </AccordionItem>
      <AccordionItem title="12 September 2022" mdxType="AccordionItem">
        <h3><strong parentName="h3">{`Website Updates`}</strong>{` `}<br />{` `}<br /></h3>
        <p><strong parentName="p">{`Website Documentation - Release Version : 2.0.0`}</strong>{` `}<br />{` `}<br /></p>
        <p><strong parentName="p">{`Update`}</strong>{` : `}<inlineCode parentName="p">{`menu`}</inlineCode>{` structure and information architecture `}<br />{` `}<br />{`
`}<strong parentName="p">{`Update`}</strong>{` : add overview page for `}<inlineCode parentName="p">{`foundation`}</inlineCode>{`, `}<inlineCode parentName="p">{`component`}</inlineCode>{` and `}<inlineCode parentName="p">{`pattern`}</inlineCode>{` `}<br />{` `}<br />{`
`}<strong parentName="p">{`Update`}</strong>{` : content `}<inlineCode parentName="p">{`login & Register`}</inlineCode>{` and `}<inlineCode parentName="p">{`Onboarding`}</inlineCode>{` on pattern menu `}<br />{` `}<br />{`
`}<strong parentName="p">{`Update`}</strong>{` : update global search optimization `}<inlineCode parentName="p">{`Lurn search`}</inlineCode>{` feature `}<br />{` `}<br />{`
`}<strong parentName="p">{`Update`}</strong>{` : add guideline `}<inlineCode parentName="p">{`anchor text`}</inlineCode>{` component in tab section `}<br />{` `}<br />{`
`}<strong parentName="p">{`Update`}</strong>{` : add guideline `}<inlineCode parentName="p">{`button`}</inlineCode>{` component in tab section `}<br />{` `}<br />{`
`}<strong parentName="p">{`Update`}</strong>{` : add guideline `}<inlineCode parentName="p">{`checkbox`}</inlineCode>{` component in tab section `}<br />{` `}<br />{`
`}<strong parentName="p">{`Update`}</strong>{` : add `}<inlineCode parentName="p">{`multibrand section`}</inlineCode>{` feature to accomodate liveview of different brand’s `}<br />{` `}<br />{`
`}<strong parentName="p">{`Update`}</strong>{` : add guideline `}<inlineCode parentName="p">{`installation`}</inlineCode>{` and `}<inlineCode parentName="p">{`configuration`}</inlineCode>{` enhancement Website platform `}<br />{` `}<br />{`
`}<strong parentName="p">{`Update`}</strong>{` : add guideline `}<inlineCode parentName="p">{`installation`}</inlineCode>{` and `}<inlineCode parentName="p">{`configuration`}</inlineCode>{` enhancement Android platform `}<br />{` `}<br />{`
`}<strong parentName="p">{`Bug Fix`}</strong>{` : `}<inlineCode parentName="p">{`resposive`}</inlineCode>{` view for homepage, about us and all content layout`}</p>
        <br />
        <h3><strong parentName="h3">{`Development Library Updates`}</strong>{` `}<br />{` `}<br /></h3>
        <p><strong parentName="p">{`Website (Legion-UI) - Release Version : 1.0.9`}</strong>{` `}<br />{` `}<br />{`
`}<strong parentName="p">{`Update`}</strong>{` : change dependency management to `}<inlineCode parentName="p">{`nexus`}</inlineCode>{` private package `}<br />{` `}<br />{`
`}<strong parentName="p">{`Update`}</strong>{` : `}<inlineCode parentName="p">{`color scale`}</inlineCode>{` 25-900 tokens template themes `}<br />{` `}<br />{`
`}<strong parentName="p">{`Bug Fix`}</strong>{` : `}<inlineCode parentName="p">{`Heading`}</inlineCode>{` and `}<inlineCode parentName="p">{`Body`}</inlineCode>{` component tokens fonts family `}<br />{` `}<br />{`
`}<strong parentName="p">{`Update`}</strong>{` : `}<inlineCode parentName="p">{`badge`}</inlineCode>{` variants option primary, secondary, information, error, success and warning `}<br />{` `}<br />{`
`}<strong parentName="p">{`Bug Fix`}</strong>{` : `}<inlineCode parentName="p">{`typography`}</inlineCode>{` issue not show the FontFamily from themes`}</p>
        <br />
        <h3><strong parentName="h3">{`Design Library Updates`}</strong>{` `}<br />{` `}<br /></h3>
        <p><strong parentName="p">{`Update`}</strong>{` : Added `}<inlineCode parentName="p">{`bottom sheet`}</inlineCode>{` component in Legion Project - UI Library - Website UI Component `}<br />{` `}<br />{`
`}<strong parentName="p">{`Update`}</strong>{` : Added `}<inlineCode parentName="p">{`top bar`}</inlineCode>{` component in Legion Project - UI Library - Website UI Component `}<br />{` `}<br />{`
`}<strong parentName="p">{`Update`}</strong>{` : Added `}<inlineCode parentName="p">{`snackbar`}</inlineCode>{` component in Legion Project - UI Library - Website UI Component `}<br />{` `}<br />{`
`}<strong parentName="p">{`Update`}</strong>{` : Added `}<inlineCode parentName="p">{`chips`}</inlineCode>{` component in Legion Project - UI Library - Website UI Component`}<br />{` `}<br />{`
`}<strong parentName="p">{`Update`}</strong>{` : Added `}<inlineCode parentName="p">{`badge`}</inlineCode>{` component in Legion Project - UI Library - Website UI Component`}<br />{` `}<br />{`
`}<strong parentName="p">{`Update`}</strong>{` : Added `}<inlineCode parentName="p">{`avatar`}</inlineCode>{` component in Legion Project - UI Library - Website UI Component`}</p>
        <h3><strong parentName="h3">{`Foundation/Helper Kit`}</strong>{` `}<br />{` `}<br /></h3>
        <p><strong parentName="p">{`Update`}</strong>{` : `}<inlineCode parentName="p">{`color scale`}</inlineCode>{` and documentation in Legion Project - UI Library - Foundation`}</p>
        <br />
        <br />
      </AccordionItem>
      <AccordionItem title="12 March 2022" mdxType="AccordionItem">
        <h3><strong parentName="h3">{`Website Updates`}</strong>{` `}<br />{` `}<br /></h3>
        <p><strong parentName="p">{`Website Documentation - Release Version : 1.2.7`}</strong>{` `}<br />{` `}<br /></p>
        <ul>
          <li parentName="ul">{`Update navbar menu for responsive mobile`}</li>
          <li parentName="ul">{`Update layout section content`}</li>
          <li parentName="ul">{`Added new source code component`}</li>
          <li parentName="ul">{`Layout pixel perfect`}</li>
          <li parentName="ul">{`Responsiveness`}</li>
          <li parentName="ul">{`Component page setup`}</li>
          <li parentName="ul">{`Adding SASS support for css modularization at docs`}</li>
          <li parentName="ul">{`Static search bar problem`}</li>
          <li parentName="ul">{`Reduce too big asset file`}</li>
          <li parentName="ul">{`Fixing minor request`}</li>
          <li parentName="ul">{`Update responsive view`}</li>
          <li parentName="ul">{`Update design and illustration`}</li>
          <li parentName="ul">{`Update foundation page`}</li>
          <li parentName="ul">{`Update motion page`}</li>
          <li parentName="ul">{`Added home / landing page`}</li>
          <li parentName="ul">{`Added library & flow page`}</li>
          <li parentName="ul">{`Added project structure page`}</li>
          <li parentName="ul">{`Added writting for pattern page`}</li>
          <li parentName="ul">{`Added guideline pattern page`}</li>
          <li parentName="ul">{`Added search feature`}</li>
        </ul>
        <h3><strong parentName="h3">{`Development Library Updates`}</strong></h3>
        <ul>
          <li parentName="ul">{`Add component checkbox`}</li>
          <li parentName="ul">{`Add component Heading`}</li>
          <li parentName="ul">{`Add component Body`}</li>
          <li parentName="ul">{`Add component Caption`}</li>
          <li parentName="ul">{`Add design token integration from figma`}</li>
          <li parentName="ul">{`Create theme base for default style`}</li>
          <li parentName="ul">{`Add component switch`}</li>
          <li parentName="ul">{`Add component divider`}</li>
        </ul>
        <h3><strong parentName="h3">{`Design Library Updates`}</strong></h3>
        <ul>
          <li parentName="ul">{`Update Component figma auto layout and variant updates`}</li>
          <li parentName="ul">{`Added Component Anchor Text for Mobile`}</li>
          <li parentName="ul">{`Added Component Anchor Text Area for Mobile`}</li>
          <li parentName="ul">{`Added Component Anchor Text for website`}</li>
          <li parentName="ul">{`Added Component Anchor Text Area for website`}</li>
          <li parentName="ul">{`Update helper with IOS 14 kit`}</li>
          <li parentName="ul">{`Added component divider`}</li>
        </ul>
        <br />
        <br />
  </AccordionItem>
      <AccordionItem title="12 March 2022" mdxType="AccordionItem">
        <h3><strong parentName="h3">{`Website Updates`}</strong>{` `}<br />{` `}<br /></h3>
        <p><strong parentName="p">{`Website Documentation - Release Version : 0.8.5`}</strong>{` `}<br />{` `}<br /></p>
        <ul>
          <li parentName="ul">{`Added Support page menu`}</li>
          <li parentName="ul">{`Fix Spacing between content at the docs`}</li>
          <li parentName="ul">{`Change image to vector for a better design presentation`}</li>
          <li parentName="ul">{`Fix title and content margin to larger size`}</li>
          <li parentName="ul">{`Add props control to hide and show Content Toggler in Code Section`}</li>
          <li parentName="ul">{`Added monthly publication menu`}</li>
          <li parentName="ul">{`Update state draft Component`}</li>
          <li parentName="ul">{`Update preview code block`}</li>
        </ul>
        <h3><strong parentName="h3">{`Development Library Updates`}</strong></h3>
        <ul>
          <li parentName="ul">{`Add component checkbox`}</li>
          <li parentName="ul">{`Add component Heading`}</li>
          <li parentName="ul">{`Add component Body`}</li>
          <li parentName="ul">{`Add component Caption`}</li>
          <li parentName="ul">{`Add design token integration from figma`}</li>
          <li parentName="ul">{`Create theme base for default style`}</li>
          <li parentName="ul">{`Add component switch`}</li>
        </ul>
        <h3><strong parentName="h3">{`Design Library Updates`}</strong></h3>
        <ul>
          <li parentName="ul">{`Added helper component libbrary`}</li>
          <li parentName="ul">{`Added Component checkbox for Mobile`}</li>
          <li parentName="ul">{`Added Component textfield for website`}</li>
          <li parentName="ul">{`Added Component switch for website`}</li>
          <li parentName="ul">{`Added Component textfield for mobile`}</li>
          <li parentName="ul">{`Update UI Foundation Color System and Typography`}</li>
        </ul>
        <br />
        <br />
  </AccordionItem>
      <AccordionItem title="12 January 2022" mdxType="AccordionItem">
        <h3><strong parentName="h3">{`Website Updates`}</strong>{` `}<br />{` `}<br /></h3>
        <p><strong parentName="p">{`Website Documentation - Release Version : 0.5.2`}</strong>{` `}<br />{` `}<br /></p>
        <ul>
          <li parentName="ul">{`Layout pixel perfect`}</li>
          <li parentName="ul">{`Responsiveness`}</li>
          <li parentName="ul">{`Component page setup`}</li>
          <li parentName="ul">{`Adding SASS support for css modularization at docs`}</li>
          <li parentName="ul">{`Static search bar problem`}</li>
          <li parentName="ul">{`Too big asset file`}</li>
          <li parentName="ul">{`Fixing minor request`}</li>
          <li parentName="ul">{`Dynamic Components Version Dropdown`}</li>
          <li parentName="ul">{`Sub-categorized sidebar Problem`}</li>
          <li parentName="ul">{`Dynamic Components Version Dropdown`}</li>
          <li parentName="ul">{`Sub-categorized sidebar Problem`}</li>
          <li parentName="ul">{`Filling content with pattern design`}</li>
          <li parentName="ul">{`Research iframe and sidebar`}</li>
          <li parentName="ul">{`Logo`}</li>
          <li parentName="ul">{`Favicon`}</li>
          <li parentName="ul">{`Pattern Library separated content`}</li>
          <li parentName="ul">{`Minor fixing css DocItem, DocContainer`}</li>
          <li parentName="ul">{`Responsive Search static component`}</li>
          <li parentName="ul">{`Fix viewport ordering trough navbar dan sidebar`}</li>
          <li parentName="ul">{`Fix margin card homepage`}</li>
          <li parentName="ul">{`Fix Navbar padding similar to content`}</li>
          <li parentName="ul">{`Add Fullscreen anchor link at bottom of iframe`}</li>
          <li parentName="ul">{`Content toggler for component and style`}</li>
        </ul>
        <h3><strong parentName="h3">{`Development Library Updates`}</strong></h3>
        <ul>
          <li parentName="ul">{`Cretate private dependency in verdacio`}</li>
          <li parentName="ul">{`Create draft component button website`}</li>
          <li parentName="ul">{`Add lerna to development packge website`}</li>
        </ul>
        <h3><strong parentName="h3">{`Design Library Updates`}</strong></h3>
        <ul>
          <li parentName="ul">{`Added Project Pattern Library Website and Pattern Library Mobile`}</li>
          <li parentName="ul">{`Adding Website Login Pattern`}</li>
          <li parentName="ul">{`Added Mobile Login Pattern`}</li>
          <li parentName="ul">{`Added Project UI Library, Project Library and Helpers Library`}</li>
          <li parentName="ul">{`Added UI Component Website`}</li>
          <li parentName="ul">{`Added UI Foundation template`}</li>
        </ul>
        <br />
        <br />
  </AccordionItem>
    </Accordion>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      